import ImageOne from '../images/WhyImages/img1.png'
import ImageTwo from '../images/WhyImages/img2.png'
import ImageThree from '../images/WhyImages/img3.png'
import ImageFour from '../images/WhyImages/img4.png'




export const WhyData = [
    {
        image: ImageOne,
        description: 'Mayor crecimiento económico proyectado en Sudamérica entre 2020 y 2024(FMI)'
    },
    { 
        image: ImageTwo,
        description: 'Menor tasa corporativa de la región'
    },
    {
        image: ImageThree,
        description: 'Menores costos de energía en todo el país'
    },
    {
        image: ImageFour,
        description: 'Resiliencia económica a choques externos (crisis en Argentina y Brasil)'
    }
];