import styled from "styled-components";
import React, { useState, useEffect } from 'react';
import {getMultipleFiles} from '../data/api';
import BeatLoader from 'react-spinners/BeatLoader';
import { Button } from "../components/Button";
import { IoMdArrowRoundForward } from "react-icons/io";
import TextEffect from '../images/TitleEffect.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Terrains.css'
import NoFoundImg from '../images/404/noresult.png'

import NoResult from '../images/404/noresult.png'
import { motion } from 'framer-motion';

const Section = styled.section`
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 100vh;
` 
const Title = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    font-weight: 700;
    padding: 3rem 0;
    padding-bottom: 2rem;
    padding-left: 3rem;

    h1
    {
        font-size: clamp(1rem,8vw,2rem);
        font-weight: 400;
    }
    h4
    {
        color:#ffb400;
        font-weight: 600;
        font-style: italic;
    }
    span
    {
        color:#ffb400;
        font-weight: 600;
        font-style: italic;

        background: url(${TextEffect}) repeat-y;
	    -webkit-background-clip: text;
	    background-clip: text;


	    -webkit-text-fill-color: transparent;
	    -webkit-animation: aitf 37s linear infinite;

	    -webkit-transform: translate3d(0,0,0);
	    -webkit-backface-visibility: hidden;    
    }


`

const NavSpace = styled.div`
    background: black;
    height: 60px;
    width: 100%;
`
const Background = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    background: white;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    display: flex;
`
const CardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding-left: 3rem;
    @media (max-width: 1920px) 
    {
        grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: 1532px) 
    {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 1294px) 
    {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 990px) 
    {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 676px) 
    {
        grid-template-columns: repeat(1, 1fr);
        padding: 0 3rem;
    }
    @media (max-width: 360px) 
    {
        grid-template-columns: repeat(1, 1fr);
        padding: 0 2rem;
    }

`

const Card = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-right: 40px;
    border-radius: 15px;
    margin-bottom: 40px;
    @media (max-width: 676px) 
    {
        padding-right: 0;
    }

`

const CardInfo = styled.div`
    padding-top: 10px;
    overflow: hidden;
    height:120px;
    margin-bottom: 10px;
`
const Price = styled.div`
    font-weight: 500;
    font-size: 20px;
    span
    {
        color:#ffb400;
    }
`
const Ha = styled.div`
    font-weight: 500;
`
const Ubication = styled.div`
    
overflow:hidden; 
text-overflow:ellipsis;
display:-webkit-box; 
-webkit-box-orient:vertical;
-webkit-line-clamp:2;
`

const Arrow = styled(IoMdArrowRoundForward)`
    margin-left: 0.5rem;

`;
const FilterForm = styled.div`
    display:flex;
    padding: 0 3rem;
    margin-bottom: 2.5rem;

     @media (max-width: 650px) 
    {
        flex-direction: column;
        align-items: center;
    }
    @media (max-width: 360px)
    {
        padding: 0 2rem;
    }
    select
    {
        margin-left: 5px;

        width: 120px;
        @media (max-width: 900px) 
        {
            margin-left: 0;
        }
    }
    a
    {
        @media (max-width: 650px) 
        {
            margin-top: 20px;
        }    
         @media (max-width: 900px) 
        {
            height: 42px;
            align-self: center;
        }    
    }

`
const InputContainer = styled.div`
    border: 1px solid rgba(0,0,0,0.02);
    border-radius: 5px;
    padding: 10px;
    margin-right: 10px;
    background: rgba(0,0,0,0.025);
    @media (max-width: 900px) 
    {
        display:flex;
        flex-direction: column;
        width: 155px
    }
    @media (max-width: 650px) 
    {
        margin-right: 0;
        width: 100%;
        align-items:center;
        select
        {
           width: 90%;
           height: 30px; 
        }
    }
}
`

const NotFoundContainer = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3rem;
    padding-top: 0;
`

const NotFoundImg = styled.img`
    @media (max-width: 590px) 
    {
        width: 100%;
    }
`

const NotFoundTitle = styled.div`
    font-size: clamp(1rem,8vw,2rem);
    font-weight: 400;
    margin-bottom: 2rem;
`

function Terrains() {

    const [terrainsArray, setTerrainsArray] = useState([]);
    const [filterArray, setFilterArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pais, setPais] = useState('Todos')
    const [dpto, setDpto] = useState('Todos')
    const [ha, setHa] = useState(0)


    function pointsPrice(price) {
        return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

    const getMultipleFilesList = async () => {
        try 
        {

            const fileslist = await getMultipleFiles();
            setTerrainsArray(fileslist)
            setFilterArray(fileslist)
            setLoading(true)

        }
        catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getMultipleFilesList();
        // eslint-disable-next-line
    }, [])
    function handleHa(arrayFilter)
    {
        if (Number(ha) === 0)
        {
            return arrayFilter;
        }
        if (Number(ha) === 500) 
        {
            return arrayFilter.filter((item) => item.ha <= 500);
        }
        if (Number(ha) === 1000) {
            return arrayFilter.filter((item) => item.ha >= 500 && item.ha <= 1000 );
        }
        if (Number(ha) === 5000) {
            return arrayFilter.filter((item) => item.ha >= 1000 && item.ha <= 5000);
        }
        if (Number(ha) === 10000) {
            return arrayFilter.filter((item) => item.ha >= 5000 && item.ha <= 10000);
        }
        if (Number(ha) === 10001) {
            return arrayFilter.filter((item) => item.ha >= 10000);
        }

    }
    function handlePais(value)
    {
        if (value === 'Paraguay')
        {
            setDpto('Todos');
        }
        setPais(value)
    }
    function handleFilter() 
    {
        if (pais === 'Todos') 
        {
            setFilterArray(handleHa(terrainsArray))
        }

        if(pais === 'Paraguay')
        {
            if(dpto === 'Todos')
            {
                setFilterArray(handleHa(terrainsArray.filter((item) => 'Paraguay' === item.pais)))
            }
            else
            {
                setFilterArray(handleHa(terrainsArray.filter((item) => dpto === item.dpto)))
            }
        }

        if(pais === 'Chile')
        {
            setFilterArray(handleHa(terrainsArray.filter((item) => 'Chile' === item.pais)))
        }
        if (pais === 'Argentina') {
            setFilterArray(handleHa(terrainsArray.filter((item) => 'Argentina' === item.pais)))
        }
        if (pais === 'Brasil') {
            setFilterArray(handleHa(terrainsArray.filter((item) => 'Brasil' === item.pais)))
        }
    }


    return (
        <>{loading ? 
           <Section>
               <NavSpace/>
                <Title><h1>TERRENOS <span>DISPONIBLES</span></h1></Title>
                <FilterForm>
                    <InputContainer>
                        <label>Pais</label>
                        <select onChange={(e) => handlePais(e.target.value)} >
                            <option value='Todos'>Todos</option>
                            <option value='Paraguay'>Paraguay</option>
                            <option value='Chile'>Chile</option>
                            <option value='Brasil'>Brasil</option>
                            <option value='Argentina'>Argentina</option>
                        </select>
                    </InputContainer>
                    <InputContainer>
                    <label>Departamento</label>
                    {(() => {
                        if (pais === 'Paraguay') 
                        {
                            return (
                                <select onChange={(e) => setDpto(e.target.value)} >
                                    <option value='Todos'>Todos</option>
                                    <option value='Alto Paraguay'>Alto Paraguay</option>
                                    <option value='Alto Parana'>Alto Parana</option>
                                    <option value='Amambay'>Amambay</option>
                                    <option value='Boquerón'>Boquerón</option>
                                    <option value='Caaguazú'>Caaguazú</option>
                                    <option value='Caazapá'>Caazapá</option>
                                    <option value='Canindeyú'>Canindeyú</option>
                                    <option value='Central'>Central</option>
                                    <option value='Concepción'>Concepción</option>
                                    <option value='Cordillera'>Cordillera</option>
                                    <option value='Guairá'>Guairá</option>
                                    <option value='Itapúa'>Itapúa</option>
                                    <option value='Misiones'>Itapúa</option>
                                    <option value='Ñeembucú'>Ñeembucú</option>
                                    <option value='Paraguarí'>Paraguarí</option>
                                    <option value='Presidente Hayes'>Presidente Hayes</option>
                                    <option value='San Pedro'>San Pedro</option>
                                </select>
                            )
                        }
                        else
                        {
                            return (
                                <select>
                                    <option value='Todos'>Todos</option>
                                </select> 
                            )
                        }
                    })()}
                    </InputContainer>
                    <InputContainer>
                    <label>Hectarea</label>
                    <select onChange={(e) => setHa(e.target.value)} >
                        <option value={0}>Todos</option>
                        <option value={500}>0 - 500</option>
                        <option value={1000}>500 - 1000</option>
                        <option value={5000}>1000 - 5000</option>
                        <option value={10000}>5000 - 10000</option>
                        <option value={10001}>10000 y mas</option>
                    </select>
                    </InputContainer>
                    <Button style={{padding: '10px'}} to='' onClick={() => handleFilter()}>Filtrar</Button>
                </FilterForm>
                {filterArray.length === 0
                ?
                <motion.div
                animate={{ x: ['+10vw', '0vw'], opacity: [0 , 1 ]}}
                >
                <NotFoundContainer>
                    <NotFoundTitle>No se encontró  resultados.</NotFoundTitle>
                    <NotFoundImg src={NoResult}></NotFoundImg>
                </NotFoundContainer>
                    </motion.div>
                : 
               <CardContainer>

                    {filterArray.map((item, index) =>
                    <Card key={index}>
                        <LazyLoadImage
                        effect="blur"
                        src={item.files[0].filePath}
                        alt={NoFoundImg}
                        height="250px"
                        width="100%"
                        style={{ borderRadius: "15px"}}
                        />
                        <CardInfo>
                                <Price><span>US$ </span>{pointsPrice(item.prec)} {item.porh ? 'p/ha' : ''}</Price>
                                <Ha>{pointsPrice(item.ha)} ha</Ha>
                            <Ubication>{item.loc}, {item.dpto}, {item.pais}</Ubication>
                        </CardInfo>
                        <Button to={`/terrain/${item._id}`} type="button">
                            Ver Más
                            <Arrow />
                        </Button>
                    </Card>
                )}
                </CardContainer>
            }
            </Section>
        :
            <Background>
                <BeatLoader
                    color={'#ffb400'}
                    size={50}
                />
            </Background>
        }
        </>
    )
}
export default Terrains;