import React from 'react'
import styled from 'styled-components'
import { Button } from './Button';
import InfiniteCarousel from 'react-leaf-carousel';
import TextEffect from '../images/TitleEffect.png';
import { IoMdArrowRoundForward } from 'react-icons/io';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NoFoundImg from '../images/404/noresult.png'

const Section = styled.section`
    width: 100%;
    height: 100%;
    padding-bottom: 4rem;

`
const Title = styled.div`
    padding-bottom: 2rem;
    padding-left: 4rem;
    @media (max-width: 1230px)
    {
        padding-bottom: 0;  
    } 
    @media (max-width: 720px) 
    {
        padding: 0 3rem;
    }
    @media (max-width: 384px) 
    {
        padding: 0 2rem;
    }
    h1
    {

        font-size: clamp(1rem,8vw,2rem);
        font-weight: 400;
    }
    h4
    {
        color: #ffb400;
        font-weight: 600;
        font-style: italic;
    }

    span
    {
        color:#ffb400;
        font-weight: 600;
        font-style: italic;
	  
        background: url(${TextEffect}) repeat-y;
	    -webkit-background-clip: text;
	    background-clip: text;


	    -webkit-text-fill-color: transparent;
	    -webkit-animation: aitf 37s linear infinite;

	    -webkit-transform: translate3d(0,0,0);
	    -webkit-backface-visibility: hidden;
    }

    @keyframes aitf 
    {
        0% { background-position: 0% 50%; }
        100% { background-position: 100% 50%; }
    }
`
const Card = styled.div`
    display:flex;
    flex-direction: column;
    width: 90%;
    height: 450px;
    margin: 20px;
    border-radius: 15px;
    margin-bottom: 70px;
    transition: all 0.4s ease;
    &:hover
    {
        box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    }
`

const CardInfo = styled.div`
    padding-top: 10px;
    overflow: hidden;
    height:120px;
    margin-bottom: 10px;
    padding: 20px;
`
const Price = styled.div`
    font-weight: 500;
    font-size: 20px;
    span
    {
        color:#ffb400;
    }
`
const Ha = styled.div`
    font-weight: 500;
`
const Ubication = styled.div`
    
overflow:hidden; 
text-overflow:ellipsis;
display:-webkit-box; 
-webkit-box-orient:vertical;
-webkit-line-clamp:2;
`

const Arrow = styled(IoMdArrowRoundForward)`
    margin-left: 0.5rem;

`;


const MorePopular = ({ infoCards} ) => {

    
    function pointsPrice(price) {
        return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

  
    return (
        <Section>
            <Title>
                <h4>Las mejores opciones</h4>
                <h1>TERRENOS MAS <span>POPULARES</span></h1>
            </Title>
            <InfiniteCarousel
                breakpoints={[
                    {
                        breakpoint: 1920,
                        settings: 
                        {
                            slidesToShow: 5,
                            slidesToScroll: 5
                        }
                    },
                    {
                        breakpoint: 1600,
                        settings: 
                        {
                            slidesToShow: 4,
                            slidesToScroll: 4
                        }
                    },
                    {
                        breakpoint: 1200,
                        settings: 
                        {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 880,
                        settings: 
                        {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 590,
                        settings: 
                        {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },

                ]}
                dots={false}
                showSides={false}
                sideSize={0.1}
                slidesToScroll={6}
                slidesToShow={6}
                scrollOnDevice={false}
                lazyLoad={true}
                swipe={true}
                autoCycle={true}
                cycleInterval={4000}
            >
                    {infoCards.map((item, index) => 
                    {
                        return (
                            <Card key={index}>
                                <LazyLoadImage
                                    effect="blur"
                                    src={item.files[0].filePath}
                                    alt={NoFoundImg}
                                    height="250px"
                                    width="100%"
                                    style={{ borderRadius: "15px" }}
                                />
                                <CardInfo>
                                    <Price><span>US$ </span>{pointsPrice(item.prec)}
                                    {item.porh ? 'p/ha' : ''}</Price>
                                    <Ha>{pointsPrice(item.ha)} ha</Ha>
                                    <Ubication>{item.loc}, {item.dpto}, {item.pais}</Ubication>
                                </CardInfo>
                                <Button style={{ margin: "20px" }} to={`/terrain/${item._id}`} type="button">
                                    Ver Más
                                    <Arrow/>
                                    </Button>
                            </Card>
                        )})
                    }
            </InfiniteCarousel>
        </Section>
    )
}


export default MorePopular
