import emailjs from 'emailjs-com';
import styled from 'styled-components';

import React  from 'react';
import { Button } from './Button';

const FormContainer = styled.div`
    height: 100%;
    width: 100%;
    @media (max-width: 1005px) 
    {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`

const Form = styled.form`
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    padding: 4rem;
    @media (max-width: 1005px) 
    {
        padding: 3rem;
        padding-top: 0;
    }
    @media (max-width: 514px) 
    {
       padding: 2rem; 
        padding-top: 0;
    }
`
const Input = styled.input`
    height: 30px;
    width: 100%;
    display:flex;
    text-align: start;
    padding-left: 6px;
`
const TextAreaContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 0.5rem;

    @media (max-width: 1005px) 
    {
        padding: 0.5rem 0;
    }
    h4
    {
        padding-bottom: 5px;
    }
    
`

const Textarea = styled.textarea`
    height: 80%;
    width: 100%;
    padding:6px;
    resize: none;

    ::-webkit-scrollbar 
    {
        width: 5px;
    }
        
    ::-webkit-scrollbar-track 
    {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: black;
    }
        
    ::-webkit-scrollbar-thumb 
    {
        background-color: #ffb400;
    }
    @media (max-width: 1005px) 
    {
        height: 200px;
    }
`

const InputContainer = styled.div`
    height: 25%;
    padding: 0.5rem;
    @media (max-width: 1005px) 
    {
        padding: 0.5rem 0;
    }
    h4
    {
        padding-bottom: 5px;
    }
`

const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('gmail', 'template_zyg6kar', e.target, 'user_BMWwuMyLTND1rVb7a9vof')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    e.target.reset()
};

const FormContact = () => {
    return (
        <FormContainer>
            <Form onSubmit={sendEmail}>
                <InputContainer>
                    <h2>Contactanos!</h2>
                    <br></br>
                    <h4>Nombre:</h4>
                    <Input type="text" placeholder="Nombre" name="name" noValidate></Input>
                </InputContainer>
                <InputContainer>
                    <h4>Email:</h4>
                    <Input type="email" placeholder="Correo" name="email" noValidate></Input>
                </InputContainer>
                <InputContainer>
                    <h4>Asunto:</h4>
                    <Input type="text" placeholder="Asunto" name="subject" noValidate></Input>
                </InputContainer>
                <TextAreaContainer>
                    <h4>Mensaje:</h4>
                    <Textarea placeholder="Tu Mensaje" name="message" noValidate></Textarea>
                </TextAreaContainer>
                <InputContainer>
                    <Button to='/contact' type="submit">Enviar Mensaje</Button>
                </InputContainer>
            </Form>
        </FormContainer>
    )
}

export default FormContact