import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Button = styled(Link)`
    background: #ffb400;
    white-space: nowrap;
    outline: none;
    border: none;
    min-width: 100px;
    max-width: 200px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    color: #fff;
    font-size:14px;
    
    &:hover{
        transform: translateY(-7px);
    }
`