import React, { useContext } from 'react';
import styled, {css} from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import {FaBars} from 'react-icons/fa';
import AuthContext from '../context/AuthContext';
import LogOutBtn from './LogOutBtn';

const Nav = styled.nav`
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index: 100;
    position: absolute;
    width: 100%;
    background: rgba(0,0,0,0.4);
`;

const NavLink = css`
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
`

const Logo = styled(Link)`
    ${NavLink}
    color: #fff;
    font-style: italic;
`;

const MenuBars = styled(FaBars)`
    display: none;
    @media (max-width: 760px)
    {
        display: block;
        height: 100%;
        width: auto;
        color: white;
    }
`;

const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: -48px;

    @media (max-width: 760px)
    {
        display: none;
    }
`;

const NavMenuLinks = styled(Link)`
    ${NavLink}  
`;

const NavBtn = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;  
    
    @media (max-width: 760px)
    {
        display: none;
    }
`;

const Navbar = ({toggle}) => {
    const { loggedIn } = useContext(AuthContext);

    return (
        <Nav>
            {
                loggedIn === true && (
                    <NavBtn>
                        <LogOutBtn />
                    </NavBtn>
                )
            }  
            {
                loggedIn === false && (
                    <Logo to='/'>CPI Sudamerica</Logo>
                )
            }
            <MenuBars onClick={toggle} />
            <NavMenu>
                <NavMenuLinks to='/'>
                    Inicio
                </NavMenuLinks>
                <NavMenuLinks to='/terrains'>
                    Terrenos
                </NavMenuLinks>
                <NavMenuLinks to='/aboutUs'> 
                    Sobre Nosotros
                </NavMenuLinks>
            </NavMenu>
            <NavBtn>
                <Button to="/contact" style={{ padding: "14px 40px"}}>Contactanos</Button>
            </NavBtn>
        </Nav>
    )
}

export default Navbar
