import styled from 'styled-components'
import React, { useEffect } from 'react';

import {StaffData} from '../data/StaffData'
import NoFoundImg from '../images/404/noresult.png'

import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer'
const Section = styled.section`
    margin: 4rem;
    margin-top: 0;
`

const Staff = styled.div`

`
const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: center;

    @media (max-width: 1144px)
    {
       grid-template-columns: repeat(2, 1fr);
       row-gap: 2rem;
    }
    @media (max-width: 550px)
    {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 2rem;   
    }

`

const Card = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    height: 350px;
    background: white;
    border-radius: 15px;
    padding: 1rem;
    text-align: center;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    margin: auto;
    margin-top: 2rem;

    @media (max-width: 1390px) 
    {
        width: 280px;
        height: 350px;
    }
    @media (max-width: 751px)
    {
        width: 190px;
        height: 320px;
        font-size: 12px;
    }

`
const Imge = styled.img`

    width: 150px;
    height: 150px;
    border-radius: 50%; 

    @media (max-width: 1390px) 
    {
        width: 150px;
        height: 150px;
    }
`

const CardDesc = styled.div`
    display:flex;
    flex-direction: column;
    text-align: center;
    padding: 10px;
    height: 100%;
    span
    {
        font-weight: 600;
        color: rgba(0,0,0,0.2)
    }
    h4
    {
        padding:5px 0;
    }
`

const ContactLayer = () => 
{
    const { ref, inView } = useInView({
        triggerOnce: true,
    });
    const animation1 = useAnimation();
    useEffect(() => {
        if (inView) {
            animation1.start(index => ({
                x: 0,
                opacity: 1,
                transition: {
                    type: 'spring',
                    duration: 2,
                    bounce: 0.3,
                    opacity: 0,
                    delay: index * 0.3
                }
            }))
        }
        if (!inView) {
            animation1.start({ x: '-50vh', opacity: 0, transition: { duration: 0 } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView])
    return (
        <>
        <Section>
            <Staff>
                    <CardsContainer ref={ref}>
                        {StaffData.map((item,index) => (
                            <motion.div key={index}
                                custom={index}
                                animate={animation1}
                                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            >
                                <Card key={index}>
                                    <Imge src={item.img}
                                        alt={NoFoundImg}
                                    />
                                    <CardDesc>
                                        <h4>{item.name}</h4>
                                        <span>{item.cargo}</span>
                                        <h4>{item.pais}</h4>
                                    </CardDesc>
                                    <img src={item.flag} alt={NoFoundImg}></img>
                                </Card>
                            </motion.div>
                        ))}
                    </CardsContainer>
                    
            </Staff>
        </Section>
        </>
    )
}
export default ContactLayer