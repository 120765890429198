import React, { useEffect } from 'react';
import styled from 'styled-components';
import AboutImg from '../images/AboutUs/img5.jpg';
import TextEffect from '../images/TitleEffect.png';
import Img1 from '../images/AboutUs/img1.png';
import Img2 from '../images/AboutUs/img2.png';

import NoFoundImg from '../images/404/noresult.png'

import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import LiCow from '../images/LiIcons/cow.png';
import LiCorral from '../images/LiIcons/corral.png';
import LiPresident from '../images/LiIcons/president.png';
import LiAgriculture from '../images/LiIcons/trophy.png';
import LiFarmer from '../images/LiIcons/farmer.png';

const Section = styled.section`
    width: 100%;
    height: 100%;
    padding: 0 4rem;
    padding-bottom: 12rem;
    display:flex;
    max-width: fit-content;
    @media (max-width: 1230px) 
    {
        padding-bottom: 7rem;
    }
    @media (max-width: 864px) 
    {
        flex-direction:column;
    }
    @media (max-width: 720px) 
    {
        padding: 3rem;
        padding-top: 0;
    }
    @media (max-width: 384px) 
    {
        padding: 2rem;
        padding-top: 0;
        font-size: 12px;
    }
`

const Left = styled.div`
    width: 100%;
    ul
    {
        padding-left: 20px;
    }
    li
    {
        padding: 5px;
    }
`
const Title = styled.div`
    padding-bottom: 2rem;
    @media (max-width: 1080px) 
    {
        padding-bottom: 1rem;
    }
    h1
    {
        font-size: clamp(1rem,8vw,2rem);
        font-weight: 400;
    }
    h4
    {
        color: #ffb400;
        font-weight: 600;
        font-style: italic;
    }

    span
    {
        color:#ffb400;
        font-weight: 600;
        font-style: italic;

        background: url(${TextEffect}) repeat-y;
	    -webkit-background-clip: text;
	    background-clip: text;


	    -webkit-text-fill-color: transparent;
	    -webkit-animation: aitf 37s linear infinite;

	    -webkit-transform: translate3d(0,0,0);
	    -webkit-backface-visibility: hidden;
    }

    @keyframes aitf 
    {
        0% { background-position: 0% 50%; }
        100% { background-position: 100% 50%; }
    }
`

const Right = styled.div`
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    @media (max-width: 864px) 
    {
        padding-top: 4rem;
    }
    @media (max-width: 720px) 
    {
        padding-top: 3rem;
    }

`
const Ul = styled.ul`

`
const Li1 = styled.li`
    list-style-image: url(${LiCow})
    
`
const Li2 = styled.li`
    list-style-image: url(${LiCorral})
`
const Li3 = styled.li`
    list-style-image: url(${LiPresident})
`
const Li4 = styled.li`
    list-style-image: url(${LiAgriculture})
`
const Li5 = styled.li`
    list-style-image: url(${LiFarmer})
`
const CardContainer = styled.div`
    display:flex;
    margin-top: 2rem;
`
const Card = styled.div`
    display:flex;
    flex-direction: column;
    width:50%;
    align-items: center;
`
const CardImg = styled.img`
    width: 100px;
    padding: 1rem 0;
`
const CardDesc = styled.div`
    display:flex;
    text-align: center;
`
const Img = styled.img`
    height: 555px;
    width: 350px;
    @media (max-width: 445px) 
    {
        width:290px;
    }
    @media (max-width: 354px) 
    {
        width:240px;
        height: 480px;
    }
    @media (max-width: 306px) 
    {
        width:215px;
        height: 440px;
    }
`

const AboutUs = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
    });
    const animation1 = useAnimation();

    useEffect(() => {
        if (inView) {
            animation1.start({
                x: 0,
                opacity: 1,
                transition: {
                    type: 'spring',
                    duration: 2,
                    bounce: 0.3,
                    opacity: 0,
                    delay: 0.5
                }
            })
        }
        if (!inView) {
            animation1.start({ x: '50vh', opacity: 0, transition: { duration: 0 } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView])

    return (
        <Section id="about">
            <Left>
                <Title>
                    <h4>Sobre Nosotros</h4>
                    <h1>CENTRAL PARAGUAYA DE <span>INMUEBLES (CPI)</span></h1>
                </Title>
                Desde hace <b>más de 40 años</b> en el sector inmobiliario de la República del Paraguay y del Brasil, el <b>Dr Jorge Bogado Haitter</b>,
                Egresado de la Universidad Nacional de Asunción con el título de Dr. en Ciencias Veterinarias, transita un camino de amplia
                    experiencia y que hoy le da impulso a la <b>Central Paraguaya de Inmuebles Sudamérica</b>, la mayor cartera de inmuebles
                    rurales del país.
                    <br></br><br></br>
                    Cómo nacido y criado en este país supo desenvolverse en distintas actividades:
                    <br></br><br></br>
                    <Ul>
                        <Li1>Jefe de compras del mayor Frigorífico de carne vacuna del Paraguay.</Li1>
                        <Li2>Pionero del engorde a corral en Paraguay.</Li2>
                        <Li3>Fundador y Presidente de la segunda mayor exposicion Rural del Paraguay.</Li3>
                        <Li4>Especialista en reproducción animal, pasturas, soja y maiz.</Li4>
                        <Li5>Ganadero y Agricultor por tradición familiar.</Li5>
                    </Ul>
                    <br></br>
                Esta experiencia plasma sus resultados en la basta cartera de clientes globales que hoy atendemos, <b>Brasil, Reino Unido, Canada
                    Emiratos Arabes, Rusia, Estados Unidos, España, Chile, Argentina, Perú, Alemania, Francia, Italia y muchos clientes de diversos
                    países</b> que vieron en Paraguay una oportunidad de proyectar , producir y hasta residir.
                    

                <CardContainer>
                    <Card>
                        <CardImg src={Img1}/>
                        <CardDesc>
                            <p>Presentamos más de un 2.000.000 de hectáreas </p>
                        </CardDesc>
                    </Card>
                    <Card>
                        <CardImg src={Img2} />
                        <CardDesc>
                            <p>Un valor de más de USD  2.000.000.000 para desarrollo de proyectos, 
                                para residencia o conservacionismo</p>
                        </CardDesc>
                    </Card>
                </CardContainer>
            </Left>
            <Right ref={ref}>
                <motion.div animate={animation1} >
                    <Img
                        src={AboutImg}
                        alt={NoFoundImg}
                    />
                </motion.div>
            </Right>
        </Section>
    )
}

export default AboutUs
