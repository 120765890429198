import React, { useContext, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Admin from "./routes/Admin";
import Home from "./routes/Home";
import Edit from "./routes/Edit"

import Navbar from "./components/Navbar"
import Whatsapp from "./components/Whatsapp"
import Footer from "./components/Footer"
import Contact from './routes/Contact';

import GlobalStyle from './components/GlobalStyle';
import Terrains from './routes/Terrains';
import About from './routes/About';
import Terrain from './routes/Terrain';
import Login from './routes/Login';
import AuthContext from './context/AuthContext';
import NotFound from './routes/NotFound';
import DropDown from './components/DropDown';



function Router() {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const {loggedIn} = useContext(AuthContext);

    return (
            <BrowserRouter>
                <GlobalStyle />
                <Navbar toggle={toggle} />
                <DropDown isOpen={isOpen} toggle={toggle} />
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="terrain/:id" element={<Terrain />} />
                    <Route exact path="contact" element={<Contact />} />
                    <Route exact path="terrains" element={<Terrains />} />
                    <Route exact path="aboutUs" element={<About />} />
                    <Route exact path="login" element={<Login />} />
                    {loggedIn === true &&
                        <>
                            <Route exact path="admin" element={<Admin />} />
                            <Route exact path="edit/:id" element={<Edit />} />
                        </>
                    }
                <Route path='*' element={<NotFound />} />
                </Routes>
                <Footer />
                <Whatsapp />
            </BrowserRouter>
    )
}
export default Router;