import React, {useState} from 'react';
import { multipleFilesUpload} from '../data/api';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css';
import styled from 'styled-components';
import TextEffect from '../images/TitleEffect.png';
import { Button } from './Button';

const Section = styled.div`
    width: 100%;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
` 
const Title = styled.div`
    padding-bottom: 2rem;
    text-align: center;
    h1
    {
        font-size: clamp(1rem,8vw,2rem);
        font-weight: 400;
    }
    h4
    {
        font-weight: 600;
        font-style: italic;
    }

    span
    {
        color:#ffb400;
        font-weight: 600;
        font-style: italic;
	  
        background: url(${TextEffect}) repeat-y;
	    -webkit-background-clip: text;
	    background-clip: text;


	    -webkit-text-fill-color: transparent;
	    -webkit-animation: aitf 37s linear infinite;

	    -webkit-transform: translate3d(0,0,0);
	    -webkit-backface-visibility: hidden;
    }

    @keyframes aitf 
    {
        0% { background-position: 0% 50%; }
        100% { background-position: 100% 50%; }
    }
`
const Info = styled.div`
    height: 100%;
    width: 80%;
    background: #fff1ca;
    margin-bottom: 4rem;
    padding: 1rem;
    border-radius: 15px;
`

const Container= styled.div`
    display:flex;
    background: #fff1ca;
    height: 100%;
    width: 100%;
    padding: 1.5rem;
    @media (max-width: 715px) 
    {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        label
        {
            font-size: 12px;
        }
    }
    @media (max-width: 500px) 
    {
        grid-template-columns: repeat(1, 1fr);
    }
    label 
    {
        font-weight: 700;
    }

`
const Left = styled.div`
    display:flex;
    align-items: center; 
    flex-direction: column;
    width:40%;
    @media (max-width: 715px) 
    {
        width: 100%;
    }
    label
    {
        margin-bottom: 10px;
    }
`

const Right = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    @media (max-width: 715px) 
    {
        width: 100%;
    }
`
const Center = styled.div`
    width: 40%;
    display: flex;
    justify-content:center;
    @media (max-width: 715px) 
    {
        width: 100%;
    }
    @media (max-width: 500px) 
    {
        padding-top: 1rem;
    }
`
const InputContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    label
    {
        display:flex;
        justify-content: end;
        padding-right: 10px;
        align-items: center;
        text-align: end;
    }
    @media (max-width: 715px) 
    {
        grid-template-columns: repeat(1, 1fr);
        label
        {
            justify-content: start;
        }
    }
`
const Input = styled.input`
    width: 100%;
    height: 25px;
`
const Textarea = styled.textarea`
    width: 90%;
    height: 400px;
    resize: none;
    ::-webkit-scrollbar 
    {
        width: 5px;
    }
        
    ::-webkit-scrollbar-track 
    {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: black;
    }
        
    ::-webkit-scrollbar-thumb 
    {
        background-color: #ffb400;
    }
`

const ProgressContainer = styled.div`
    width: 25%;
    height: 170px;
    display:flex;
`

const FileUploadScreen = (props) => {
    const [des, setDes] = useState('');
    const [pais, setPais] = useState('');
    const [dpto, setDpto] = useState('');
    const [prec, setPrec] = useState(0);
    const [porh, setPorh] = useState(false);
    const [loc, setLoc] = useState('none');
    const [ha, setHa] = useState(0);
    const [vis, setVis] = useState(0);
    const [principal, setPrincipal] = useState(false);
    const [multipleFiles, setMultipleFiles] = useState('');

    const [multipleProgress, setMultipleProgress] = useState(0);

    const MultipleFileChange = (e) =>
    {
        setMultipleFiles(e.target.files);
        setMultipleProgress(0);
    }

    const multipleFileOptions =
    {
        onUploadProgress: (ProgressEvent) => {
            const { loaded, total } = ProgressEvent;
            const percentage = Math.floor(((loaded / 1000) * 100) / (total / 1000));
            setMultipleProgress(percentage);
        }
    }

    const UploadMultipleFiles = async () =>
    {
        setMultipleProgress(0);
        if(des === '')
        {
            return alert('Ingrese una Descripcion');
        }
        if (pais === '')
        {
            return alert('Ingrese un Pais');
        }
        if (dpto === '') {
            return alert('Ingrese un Departamento');
        }
        if (loc === '') {
            return alert('Ingrese una Localidad');
        }
        if (prec === 0)
        {
            return alert('Ingrese un Precio')
        }
        if (ha === 0) {
            return alert('Ingrese una cantidad de Hectareas');
        }
        if (vis === 0) {
            return alert('Ingrese una cantidad de Visitas');
        }
        if (multipleFiles.length === 0)
        {
            return alert('Ingrese alguna Imagen');
        }
        
        const formData = new FormData();
        formData.append('des', des);
        formData.append('pais', pais);
        formData.append('dpto', dpto);
        formData.append('prec', prec);
        formData.append('porh', porh);
        formData.append('loc', loc);
        formData.append('ha', ha);
        formData.append('vis', vis);
        formData.append('principal', principal);

        for (let i = 0; i < multipleFiles.length; i++) 
        {
            if (multipleFiles[i].type === 'image/png' || multipleFiles[i].type === 'image/jpg'
                || multipleFiles[i].type === 'image/jpeg') 
            {
                formData.append('files', multipleFiles[i])
            }
            else
            {
                return alert('Solo imagenes: .png .jpg .jpeg');
            }
        }
        await multipleFilesUpload(formData, multipleFileOptions);
        props.getMultiple();
    }
    function toggle(value) {
        return !value;
    }

    return (
        <Section>
            <Title><h1>CARGAR UN <span>TERRENO</span></h1></Title>
            <Info>
                <h4>Recordatorio:</h4>
                <p>1. Antes de hacer una carga de imagenes comprimalas para que la pagina ande 100% optimizado.
                    La pagina que recomiendo es <a href='https://tinypng.com/' target={'blanck'}>https://tinypng.com/</a>,
                    donde puede poner todas las imagenes, comprimirlas rapido y facil.
                    <br></br><br></br>
                    2. La primer imagen va a ser el que va a mostrarse en el inicio (Si esta marcado en "¿Aparecera en inicio?")
                    Y siempre la primer imagen va a estar en la pagina de terrenos o el carrucel de Mas populares.
                    <br></br><br></br>
                    3. Cuando ingrese el Departamento asegurese de que esta bien escrito. Puede fijarse en esta pagina como se escribe: <a href='https://www.sport-histoire.fr/es/Geografia/Lista_departamentos_Paraguay.php'>https://Lista</a>
                    <br></br><br></br>
                    4. El precio, hectareas y visitas debe ingresar solo numeros (Sin ningun punto, ni coma o $US) 
                    <br></br><br></br>
                </p>
            </Info>
            <Container>
                <Left>
                    <label>Descripcion:</label>
                    <Textarea onChange={(e) => setDes(e.target.value)}></Textarea>
                </Left>
                <Center>
                    <InputContainer>
                        <label>Pais:</label>
                        <Input type="text" onChange={(e) => setPais(e.target.value)} ></Input>
                        <label>Departamento:</label>
                        <Input type="text" onChange={(e) => setDpto(e.target.value)} ></Input>    
                        <label>Localidad:</label>
                        <Input type="text" onChange={(e) => setLoc(e.target.value)} ></Input>
                        <label>Precio:</label>
                        <Input type="number" onChange={(e) => setPrec(e.target.value)} ></Input>
                        <label>¿Precio Por hectarea?:</label>
                        <Input type="checkbox" checked={porh} onChange={() => setPorh(toggle)}></Input>
                        <label>Hectareas:</label>
                        <Input type="number" onChange={(e) => setHa(e.target.value)} ></Input>
                        <label>Visitas:</label>
                        <Input type="number" onChange={(e) => setVis(e.target.value)} ></Input>
                        <label>¿Aparecera en el inicio?:</label>
                        <Input type="checkbox" checked={principal} onChange={() => setPrincipal(toggle)}></Input>
                        <label>Imagenes:</label>
                        <Input type="file" onChange={(e) => MultipleFileChange(e)} multiple></Input>
                    </InputContainer>
                </Center>
                <Right>
                    <Button to='' type="button" onClick={() => UploadMultipleFiles()}>Cargar</Button>
                    <ProgressContainer>
                        <CircularProgressbar
                                value={multipleProgress}
                                text={`${multipleProgress}%`}
                                styles={buildStyles({
                                    rotation: 0.25,
                                    strokeLinecap: 'butt',
                                    textSize: '16px',
                                    pathTransitionDuration: 0.5,
                                    pathColor: `rgba(255, 180, 0, ${multipleProgress / 100})`,
                                    textColor: '#ffb400',
                                    trailColor: '#ddd',
                                    backgroundcolor: '#ffb400',
                                })}
                        />
                    </ProgressContainer>
                </Right>
            </Container>
        </Section>
    );
}
export default FileUploadScreen