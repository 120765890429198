import React, {useEffect} from 'react';
import styled from 'styled-components';
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer'

 
import TextEffect from '../images/TitleEffect.png';
import FirstImage from '../images/FirstPart-Img1.jpg';
import SecondImage from '../images/FirstPart-Img2.jpg';
import { IoMdArrowRoundForward } from 'react-icons/io';
import NoFoundImg from '../images/404/noresult.png'

import { Link } from 'react-router-dom'


const Section = styled.section`
    width: 100%;
    height: 100%;
    padding: 12rem 1rem;
    padding-left:5rem;
    @media (max-width: 1230px) 
    {
        padding: 7rem 4rem;
    }
    @media (max-width: 720px) 
    {
        padding: 3rem;
    }
    @media (max-width: 384px) 
    {
        padding: 2rem;
    }

`
const Title = styled.div`
    padding-bottom: 2rem;
    @media (max-width: 384px) 
    {
        padding-bottom: 1rem;
    }
    h1
    {
        font-size: clamp(1rem, 8vw, 2rem);
        font-weight: 400;

    span
    {
        color:#ffb400;
        font-weight: 600;
        font-style: italic;
	  
        background: url(${TextEffect}) repeat-y;
	    -webkit-background-clip: text;
	    background-clip: text;


	    -webkit-text-fill-color: transparent;
	    -webkit-animation: aitf 37s linear infinite;

	    -webkit-transform: translate3d(0,0,0);
	    -webkit-backface-visibility: hidden;
    }
    

    @keyframes aitf 
    {
        0% { background-position: 0% 50%; }
        100% { background-position: 100% 50%; }
    }

`
const SubTitle = styled.div`
    color: #ffb400;
    font-weight: 600;
    font-style: italic;
`
const Text = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding: 4rem;
    @media (max-width: 1230px) 
    {
        padding: 0;
        padding-bottom: 4rem;
    }
    @media (max-width: 720px) 
    {
        padding-bottom: 3rem;
    }
    @media (max-width: 384px) 
    {
        padding-bottom: 2rem;
        font-size: 12px;
    }

`
const Img = styled.img`
    height:555px;
    width: 350px;
    
    @media (max-width: 1390px) 
    {
        width:90%;
    }
    @media (max-width: 720px)
    {
        height: 350px;
    } 
    @media (max-width: 515px) 
    {
        height: 250px;
    }
    @media (max-width: 416px) 
    {
        height: 230px;
    }
    @media (max-width: 320px)
    {
        height: 210px;
    } 

`
const FirstPart = styled.div`
    display:flex;
    width: 100%;
    height: 100%;

    @media (max-width: 1230px) 
    {
        flex-direction: column-reverse;
        padding-left:0;
    }

`

const Left = styled.div`
    display:flex;
    width:55%;

    @media (max-width: 1230px) 
    {
        width:100%;
    }
    
    
`
const LeftPart = styled.div`
    width: 100%;
    @media (max-width: 1230px) 
    {
        display: flex;
        align-items:center;
        justify-content: center;
    }

`

const Right = styled.div`
    display:flex;
    width: 50%;
    @media (max-width: 1230px) 
    {
        width:100%;
    }
    
`
const RightPart = styled.div`
    margin-left: 20px;
    width: 100%;
    @media (max-width: 1230px) 
    {
        display: flex;
        align-items:center;
        justify-content: center;
    }
    @media (max-width: 416px) 
    {
        margin-left: 0;
    }

`
const Button = styled(Link)`
    background: #ffb400;
    white-space: nowrap;
    outline: none;
    border: none;
    min-width: 100px;
    max-width: 200px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    color: #fff;
    font-size:14px;
    
    &:hover{
        transform: translateY(-7px);
    }

    @media (max-width: 515px) 
    {
        padding: 16px 0px;
        width:90%;
    }
    @media (max-width: 416px) 
    {
        font-size:9px;
    }

`

const Arrow = styled(IoMdArrowRoundForward)`
    margin-left: 0.5rem;

`


const WhyChoose = () => {

    const { ref, inView } = useInView({
        triggerOnce: true,
    });
    const animation1 = useAnimation();
    const animation2 = useAnimation();

    useEffect(() =>
    {
        if(inView)
        {
            animation1.start({
                x: 0,
                opacity: 1,
                transition:
                {
                    type: 'spring',
                    duration: 2,
                    bounce: 0.3,
                    delay: 0.5
                }
            })
            animation2.start({
                x: 0,
                opacity: 1,
                transition:
                {
                    type: 'spring',
                    duration: 2,
                    bounce: 0.3,
                    delay: 1.0
                }
            })
        }
        if(!inView)
        {
            animation1.start({ x: '-50vw', opacity: 0, transition:{ duration: 0}})
            animation2.start({ x: '50vw', opacity: 0,  transition: { duration: 0 }})
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView])
    
    return (
        <Section>
            <FirstPart>
                <Left ref={ref}>
                    <LeftPart>
                        <motion.div animate={animation1} initial={{ opacity: 0 }}>
                            <Img
                                src={FirstImage}
                                alt={NoFoundImg}
                                style={{ marginTop: "64px"}}
                            />
                        </motion.div>
                    </LeftPart>
                    <RightPart>
                        <motion.div animate={animation2} initial={{ opacity: 0 }}>
                            <Img
                                src={SecondImage}
                                alt={NoFoundImg}
                                style={{ marginBottom: "15px", }}
                            />
                            <Button to="/terrains">
                                Ver Terrenos
                                <Arrow/>
                            </Button>
                        </motion.div>
                    </RightPart>
                </Left>
                <Right>
                    <Text>
                        <SubTitle>Paraguay</SubTitle>
                        <Title><h1>¿POR QUÉ INVERTIR EN <span>PARAGUAY</span>?</h1></Title>
                        <p>
                            En los últimos años (2006-2021), Paraguay mantuvo un
                                crecimiento promedio positivo, a pesar de la volatilidad de sus
                                principales socios comerciales,
                            <br></br><b>el crecimiento promedio fue de 3,8%</b>.
                            <br></br><br></br>
                            La inversión extranjera provino de <b>42 países del mundo</b>, 
                            <br></br>Lo que demuestra la confiabilidad del país para invertir. 
                            <br></br><br></br>
                            Invariabilidad de la tasa impositiva del impuesto a la renta
                            para las empresas por un <b>plazo de hasta 10 años.</b>
                            <br></br><br></br> <b>Tipo de cambio favorable</b> para transferencias de capital y utilidades.
                        </p>
                    </Text>
                </Right>
            </FirstPart>
        </Section>
    )
}

export default WhyChoose
