
import React from 'react';
import styled from 'styled-components';
import cloud from '../images/404/cloud.png'
import { motion } from 'framer-motion';
import TextEffect from '../images/TitleEffect.png';

const NavSpace = styled.div`
    background: black;
    height: 60px;
    width: 100%;
    position: absolute;
    top: 0;
`
const Section = styled.div`
    min-height: 100vh;
    overflow: hidden;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
`

const Title = styled.div`
    padding-top: 2rem;
    color: #ffb400;

    h1
    {
        padding-bottom: 2rem;
        font-size: clamp(1rem,8vw,2rem);
        font-weight: 400;
    }
    h4
    {
        font-weight: 600;
        font-style: italic;
    }

    span
    {
        color:#ffb400;
        font-weight: 600;
        font-style: italic;
	  
        background: url(${TextEffect}) repeat-y;
	    -webkit-background-clip: text;
	    background-clip: text;


	    -webkit-text-fill-color: transparent;
	    -webkit-animation: aitf 37s linear infinite;

	    -webkit-transform: translate3d(0,0,0);
	    -webkit-backface-visibility: hidden;
    }

    @keyframes aitf 
    {
        0% { background-position: 0% 50%; }
        100% { background-position: 100% 50%; }
    }
`
const Img = styled.img`
    padding-top: 60px;
    height: 300px;
    @media (max-width: 300px) 
    {
        height: 250px;
    }
`
function NotFound() {
    return(
        <Section>
            <NavSpace/>
            <motion.div
                animate={{ x: ['10vw', '-10vw'], opacity: [0 , 1 , 0]}}
                transition= 
                    {{
                        repeat: Infinity,
                        duration: 3,      
                    }}
                >
                <Img src={cloud}></Img>
            </motion.div>
            <Title><h1><span>404 NOT FOUND</span></h1></Title>
        </Section>

    )
}

export default NotFound;
