import Image1 from '../images/Staff/Dr.jpeg';
import Image2 from '../images/Staff/Bruno.jpeg';
import Image3 from '../images/Staff/Nelson.jpeg';
import Image4 from '../images/Staff/Mariela.jpeg';
import Image5 from '../images/Staff/⁬Ivana.jpeg';
import Image6 from '../images/Staff/Jorge.jpeg';
import Paraguay from '../images/Flags/img1.png'
import Argentina from '../images/Flags/img2.png'
import Brazil from '../images/Flags/img4.png'
import Bolivia from '../images/Flags/img5.png'
import Chile from '../images/FlagsTable/img3.png'


export const StaffData = [
    {
        img: Image1,
        name: 'Dr. Jorge Bogado',
        cargo: 'Socio Propietario',
        pais: 'Paraguay',
        flag: Paraguay
    },
    {
        img: Image2,
        name: 'Bruno Bogado',
        cargo: 'Representante',
        pais: 'Brazil',
        flag: Brazil
    },
    {
        img: Image3,
        name: 'Nelson Echenique',
        cargo: 'Representante',
        pais: 'Bolivia',
        flag: Bolivia
    },
    {
        img: Image4,
        name: 'Mariela Silva',
        cargo: 'Representante',
        pais: 'Chile',
        flag: Chile
    },
    {
        img: Image5,
        name: 'Ivana Allemandi',
        cargo: 'Representante',
        pais: 'Argentina',
        flag: Argentina
    },
    {
        img: Image6,
        name: 'Jorge Urrustarazu',
        cargo: 'Director de Proyectos',
        pais: 'Argentina',
        flag: Argentina
    }
];