import styled from 'styled-components'
import React from 'react'
import ContactImg from '../images/Contact/contact1.svg';
import FormContact from './FormContact'

import NoFoundImg from '../images/404/noresult.png'
import EmailIcon from '../images/Contact/emailicon.png'
import Telephone from '../images/Contact/telephone.png'

const Section = styled.div`
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    @media (max-width: 1005px) 
    {
       flex-direction: column;
    }
`
const Container = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    background: #efefef;
    margin: 4rem;
    border-radius: 15px;
    height: 600px;
    @media (max-width: 1005px) 
    {
       flex-direction: column;
       height: 100%;
    }
      @media (max-width: 492px) 
    {
        margin: 2rem;
    }
    
`
const Text = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1005px) 
    {
       font-size: 12px;
    }
    h1
    {
        font-size: clamp(1rem,8vw,2rem);
    }
    p
    {
        font-size: 15px;
        @media (max-width: 373px) 
        {
            font-size: 12px;
        }
    }

`

const Img = styled.img`
    height: 300px;
    width: 400px;
    @media (max-width: 813px) 
    {
        height: 250px;
       width: 100%;
    }
    @media (max-width: 763px) 
    {
        height: 200px;
        width: 100%;
        padding-top: 2rem;
    }   
    @media (max-width: 430px) 
    {
        padding-top: 1rem;
       height: 150px;
        width: 100%;
    }
    @media (max-width: 328px) 
    {
         padding-top: 0;
       height: 150px;
        width: 100%;
    }

`
const Left = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 4rem;
    @media (max-width: 1130px) 
    {
       padding: 3rem;
    }
    @media (max-width: 1005px) 
    {
       flex-direction: column;
       width: 100%;
       height: 37%;
    }
    @media (max-width: 763px) 
    {
        flex-direction: column;
    }
    @media (max-width: 763px) 
    {
        height: 50%;
    }
     @media (max-width: 514px) 
    {
       padding: 2rem; 
    }

`

const Right = styled.div`
    display: flex;
    width: 100%;
    z-index: 2;
    @media (max-width: 1005px) 
    {
       justify-content: center;
       align-items: center;
    }
`
const ContactInfo = styled.div`
    padding-top: 1rem;
    h2
    {
        padding-bottom: 1rem;
    }
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
    @media (max-width: 647px) 
    {
       grid-template-columns: repeat(2, 1fr);
       row-gap: 1rem;
    }
     @media (max-width: 373px) 
    {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 1rem;
    }

`
const ImgIcon = styled.img`
    display:flex;
    width: 40px;
    height: 40px;
    background:white;
    border-radius: 50%;
    place-self: center;
   @media (max-width: 373px) 
    {
        place-self: flex-start;
    }
`
const ContactLayer = () => 
{
    return (
        <Section>
            <Container>
                <Left>
                    <Text>
                        <h1>Gracias por tu tiempo!</h1>
                        En CPI Sudamerica estamos contentos con su consulta!  
                            <br></br>Ofrecemos a nuestros clientes una relación directa y atención personalizada, desde el corazón de Sudamérica
                    </Text>
                    <Img src={ContactImg} alt={NoFoundImg}/>
                    <ContactInfo>
                        <h2>Datos de contacto</h2>
                        <Grid>
                            <ImgIcon src={EmailIcon}/>
                            <Text>
                                <p>cpisudamerica@gmail.com</p>
                                <p>info@cpisudamerica.com</p>
                            </Text>
                            <ImgIcon src={Telephone} />
                            <Text>
                                <p>+595981361122</p>
                                <p>+5491126965089</p>
                            </Text>
                        </Grid>
                    </ContactInfo>
                </Left>
                <Right>
                    <FormContact/>
                </Right>
            </Container>
        </Section>
    )
}
export default ContactLayer