import React, { useEffect } from 'react';
import styled from 'styled-components'
import TextEffect from '../images/TitleEffect.png';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer'

const Section = styled.div`
    padding-bottom: 12rem;
    @media (max-width: 1230px) 
    {
        padding-bottom: 7rem;
    }
    @media (max-width: 720px) 
    {
        padding-bottom: 3rem;
    }
    @media (max-width: 384px) 
    {
        padding-bottom: 2rem;
    }

`
const Title = styled.div`
    padding-bottom: 2rem;
    @media (max-width: 1080px) 
    {
        padding-bottom: 1rem;
    }
    h1
    {
        font-size: clamp(1rem,8vw,2rem);
        font-weight: 400;
            
        @media (max-width: 1080px) 
        {
            padding-bottom: 0;
        }
    }
    h4
    {
        font-weight: 600;
        font-style: italic;
    }

    span
    {
        color:#ffb400;
        font-weight: 600;
        font-style: italic;
	  
        background: url(${TextEffect}) repeat-y;
	    -webkit-background-clip: text;
	    background-clip: text;


	    -webkit-text-fill-color: transparent;
	    -webkit-animation: aitf 37s linear infinite;

	    -webkit-transform: translate3d(0,0,0);
	    -webkit-backface-visibility: hidden;
    }

    @keyframes aitf 
    {
        0% { background-position: 0% 50%; }
        100% { background-position: 100% 50%; }
    }
`

const Grid = styled.div`
    display:flex;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
`
const GridColumns = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:space-around;
    height: 100%;
    width: 100%;
    div
    {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`
const GridImg = styled.img`
    width: 50px;
    @media (max-width: 534px) 
    {
        width: 40px;
    }
    @media (max-width: 480px) 
    {
        width: 30px;
    }
    @media (max-width: 370px) 
    {
        width: 20px;
    }
`
const FirstColumn = styled.div`
    display:flex;
    flex-direction: column;
    width: 150px;

    span
    {
        height: 50px;
        align-items: center;
        display:flex;
        font-size: 24px;
        @media (max-width: 480px) 
        {
            height: auto;
        }
    }
    @media (max-width: 480px) 
    {
        width: 100px;
    }
    @media (max-width: 370px) 
    {
        width: 90px;
    }
`
const Top = styled.div`
    display:flex;
    @media (max-width: 1080px) 
    {
        flex-direction: column;
        align-items: center;
    }
    @media (max-width: 384px)
    {
        font-size: 12px;
    }

`

const Table = styled.div`
    display:flex;
    width: 100%;
`
const TableColumns = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    height: 100%;
    width: 100%;

    div
    {
        width: 100%;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
`
const FirstTable = styled.div`
    display:flex;
    display:flex;
    flex-direction: column;
    width: 150px;

    span
    {
        height: 50px;
        display:flex;
        align-items: center;
        font-size: 24px;
        width: 150px;
        @media (max-width: 480px) 
        {
            height: auto;
        }
    }
    div
    {
        display:flex;
        align-items:center;
    }
    @media (max-width: 480px) 
    {
        width: 100px;
    }
    @media (max-width: 370px) 
    {
        width: 90px;
    }
`

const TableImg = styled.img`
    width: 50px;
    @media (max-width: 534px) 
    {
        width: 40px;
    }
    @media (max-width: 480px) 
    {
        width: 30px;
    }
    @media (max-width: 370px) 
    {
        width: 20px;
    }
`

const LeftOthers = styled.div`
    display:flex;
    flex-direction: column;
    width: 50%;
    padding: 0px 4rem;
    @media (max-width: 1080px) 
    {
        width: 100%;
        padding: 0px 4rem;
    }
    @media (max-width: 720px) 
    {
        padding: 0px 3rem;
    }
    @media (max-width: 384px)
    {
        padding: 0px 2rem;
    }
`
const RightOthers = styled.div`
    display:flex;
    flex-direction: column;
    width: 50%;
    padding: 0px 4rem;
    @media (max-width: 1080px) 
    {
        width: 100%;
        padding:4rem;
        padding-bottom: 0;
    }
    @media (max-width: 720px) 
    {
        padding: 3rem;
        padding-bottom: 0;
    }
    @media (max-width: 384px)
    {
        padding: 2rem;
        padding-bottom: 0;
    }
`
const Comparison = ({ gridData, tableData }) => 
{
    const { ref, inView } = useInView({
        triggerOnce: true,
    });
    const animation1 = useAnimation();
    const animation2 = useAnimation();

    useEffect(() => {
        if (inView) {
            animation1.start(index => ({
                x: 0,
                opacity: 1,
                transition: {
                    type: 'spring',
                    duration: 2,
                    bounce: 0.3,
                    opacity: 0,
                    delay: index * 0.3
                }
            }))
            animation2.start(index => ({
                x: 0,
                opacity: 1,
                transition: {
                    type: 'spring',
                    duration: 2,
                    bounce: 0.3,
                    opacity: 0,
                    delay: index * 0.3
                }
            }))
        }
        if (!inView) {
            animation1.start({ x: '-50vh', opacity: 0, transition: { duration: 0 } })
            animation2.start({ x: '50vh', opacity: 0, transition: { duration: 0 } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView])

    return (
        <Section>
            <Top ref={ref}>
                <LeftOthers>
                    <Title>
                        <h1>IMPUESTOS <span>(COMPARACIÓN)</span></h1>
                        <br></br>
                        <p>El sistema tributario de Paraguay es el<b> más sencillo y competitivo de la región</b>:
                        </p>
                    </Title>
                    <Grid>
                        <FirstColumn>
                            <span><b>PAIS</b></span>
                            <div>Impt a la renta Empresas</div>
                            <div>Impt a la renta personal</div>
                            <div>Impt al valor agregado</div>
                            <div>Total impt y aporte social </div>
                        </FirstColumn>
                        {gridData.map((item, index) => (
                            <motion.div
                                custom={index}
                                animate={animation1}
                                key={index}
                                >
                                <GridColumns>
                                        <GridImg src={item.countryImg} />
                                        <div style={{ color: item.color, fontWeight: item.fontW }}>{item.tax1}</div>
                                        <div style={{ color: item.color, fontWeight: item.fontW }}>{item.tax2}</div>
                                        <div style={{ color: item.color, fontWeight: item.fontW }}>{item.tax3}</div>
                                        <div style={{ color: item.color, fontWeight: item.fontW }}>{item.tax4}</div>
                                </GridColumns>
                            </motion.div>
                        ))}
                    </Grid>
                </LeftOthers>
                <RightOthers>
                    <Title>
                        <h1>ÍNDICE DE <span>NEGOCIOS (2021)</span></h1>
                        <br></br>
                        <p>Paraguay es primero en ranking país de <b>Índice de Clima de
                            Negocios</b> en el 2021:
                        </p>
                    </Title>
                    <Table>
                        <FirstTable>
                            <span><b>PAIS</b></span>
                            <div style={{ height: 38 }}>Ranking</div>
                        </FirstTable>
                        {tableData.map((item, index) => (
                            <motion.div
                                custom={index}
                                animate={animation2}
                                style={{ display: "flex", flexDirection: "column", width: "100%" }}
                                key={index}
                            >
                                <TableColumns>
                                    <TableImg src={item.countryImg} />
                                    <div style={{ color: item.color, fontWeight: item.fontW }}>{item.rank}</div>
                                </TableColumns>
                            </motion.div>
                        ))}
                    </Table>
                </RightOthers>
            </Top>
        </Section>
    )
}
export default Comparison;
