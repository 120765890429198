import React,{useState, useEffect} from 'react';
import FileUploadScreen from '../components/FileUpload';
import { getMultipleFiles } from '../data/api';
import styled from 'styled-components';
import { Button } from '../components/Button';
import TextEffect from '../images/TitleEffect.png';
import PopUp from '../components/PopUp'
import BeatLoader from 'react-spinners/BeatLoader'
import NoFoundImg from '../images/404/noresult.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const NavSpace = styled.div`
    background: black;
    height: 60px;
`
const Section = styled.div`
  width: 100%;
  overflow: hidden;
`

const DataBase = styled.div`
  padding: 20px;
  padding-top: 0;
  h1
  {
    text-align:center;
    font-weight: 400;
  }
`

const Buttons = styled.div`
  display:flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 368px) 
  {
    flex-direction: column;
    a
    {
      margin: 0.5rem 0 !important;
    }
  }
`
const DropContainer = styled.div`
  background: #fff1ca;
  border-radius: 15px;
  margin:20px;
  height: 100%;
`
const Title = styled.div`
    text-align: center;
    h1
    {
        font-size: 40px;
        font-weight: 400;
    }
    h4
    {
      font-size: 17px;
        font-style: italic;
    }

    span
    {
        color:#ffb400;
        font-weight: 600;
        font-style: italic;
	  
        background: url(${TextEffect}) repeat-y;
	    -webkit-background-clip: text;
	    background-clip: text;


	    -webkit-text-fill-color: transparent;
	    -webkit-animation: aitf 37s linear infinite;

	    -webkit-transform: translate3d(0,0,0);
	    -webkit-backface-visibility: hidden;
    }

    @keyframes aitf 
    {
        0% { background-position: 0% 50%; }
        100% { background-position: 100% 50%; }
    }
`

const Top = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`
const Bottom = styled.div`
  display:flex;
  height: 100%;
  padding: 10px;
  @media (max-width: 950px) 
  {
    flex-direction: column;
  }
`

const LeftFiles = styled.div`
  width: 40%;
  @media (max-width: 950px) 
  {
    width: 100%;
    padding-bottom: 1rem;
  }
  p
  {
    height: 150px;
    overflow-y: auto;
    overflow-x: none; 
    ::-webkit-scrollbar 
    {
        width: 5px;
    }
        
    ::-webkit-scrollbar-track 
    {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: black;
    }
        
    ::-webkit-scrollbar-thumb 
    {
        background-color: #ffb400;
    }
  }
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  height: 70px;
  @media (max-width: 614px) 
  {
    h4
    {
      display:flex;
      align-items:center;
      justify-content:end;
    }
    p
    {
      display:flex;
      align-items:center;
      justify-content:start;
      padding-left: 0.5rem;
    }
  }
`

const RightFiles = styled.div`
  width: 60%;
  @media (max-width: 950px) 
  {
    width: 100%;
  }
`
const TopFiles = styled.div`
  display:flex;
  width: 100%;

  @media (max-width: 614px) 
  {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`

const BottomFiles = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  width: 100%;
  @media (max-width: 676px)
  {
    .lazy-load-image-background.blur.lazy-load-image-loaded > img
    {
      height: 99px !important; 
    }
    .lazy-load-image-background.blur.lazy-load-image-loaded
    {
      height: 99px !important;
    }
  }

  }
    ::-webkit-scrollbar 
    {
      width: 5px;
      height: 5px;
    }
        
    ::-webkit-scrollbar-track 
    {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: black;

    }
        
    ::-webkit-scrollbar-thumb 
    {
        background-color: #ffb400;
    }
`

const Background = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    background: white;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    display: flex;
`

function Admin() {

  const [multipleFiles, setMultipleFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openPupOp, setOpenPupOp] = useState(false);
  const [deleteElement,setDeleteElement] = useState([])

  function pointsPrice(price) {
    return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  const getMultipleFilesList = async () =>
  {
    try 
    {
      const fileslist = await getMultipleFiles(); 
      setMultipleFiles(fileslist);
      setLoading(true)
    } 
    catch (error) 
    {
      console.log(error); 
    }
  }

  const openPupUp = async (element) =>
  {
    setDeleteElement(element);
    setOpenPupOp(true);
  }

  useEffect(() => {
    getMultipleFilesList();
  }, []);


  return (
    <>
      { loading ? 
        <Section>
          <NavSpace/>
          <PopUp trigger={openPupOp} element={deleteElement} setTrigger={setOpenPupOp} getMultiple={() => getMultipleFilesList()} />
            <FileUploadScreen getMultiple={() => getMultipleFilesList()} />
            <DataBase>
            <Title><h1>BASE DE <span>DATOS</span></h1></Title>
                  {multipleFiles.map((element) =>
                    <DropContainer key={element._id}>
                      <Top>
                        <Title>
                          <h4>{element.loc}, {element.dpto}, {element.pais}</h4>
                        </Title>
                      </Top>
                      <Bottom>
                        <LeftFiles>
                          <h4>Descripcion:</h4>
                          <p>{element.des}</p>
                        </LeftFiles >
                        <RightFiles>
                          <TopFiles>
                            <Grid>
                              <h4>Precio:</h4>
                              <p>{pointsPrice(element.prec)}</p>
                              <h4>¿Por ha?:</h4>
                              {element.porh ? <p>Si</p> : <p>No</p>}
                              <h4>Hectarias:</h4>
                              <p>{pointsPrice(element.ha)}</p>
                            </Grid>
                            <Grid>
                              <h4>Visitas:</h4>
                              <p>{element.vis}</p>
                              <h4>¿Inicio?:</h4>
                              {element.principal ? <p>Si</p> : <p>No</p>}
                              <h4>Imagenes:</h4>
                              <p>{element.files.length}</p>
                            </Grid>
                            <Buttons>
                              <Button to={`/edit/${element._id}`} type="button">Editar</Button>
                              <Button style={{ marginLeft: '20px' }} to='' type="button" onClick={() => openPupUp(element)}>Borrar</Button>
                            </Buttons>
                          </TopFiles>
                          <BottomFiles>
                            {element.files.map((file,index) =>
                              <LazyLoadImage
                                            key={index}
                                            effect="blur"
                                            src={file.filePath}
                                            alt={NoFoundImg}
                                            height="99px"
                                            width="99px"
                                        />
                            )}
                          </BottomFiles>
                        </RightFiles>
                      </Bottom>
                    </DropContainer>
                  )}
          </DataBase>
        </Section>
      :
        <Background>
          <BeatLoader
            color={'#ffb400'}
            size={50}
          />
        </Background>
      }
    </>
  );
}

export default Admin;
