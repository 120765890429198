import ImageOne from '../images/Flags/img1.png';
import ImageTwo from '../images/Flags/img2.png';
import ImageThree from '../images/Flags/img3.png';
import ImageFour from '../images/Flags/img4.png';
import ImageFive from '../images/Flags/img5.png';



export const GridData = [
    {
        countryImg: ImageOne,
        tax1: '10%',
        tax2: '10%',
        tax3: '10%',
        tax4: '35%',
        color: '#ffb400',
        fontW: '700'
    },
    {
        countryImg: ImageTwo,
        tax1: '30%',
        tax2: '35%',
        tax3: '21%',
        tax4: '106%',
        color: 'black',
        fontW: '0'
    },
    {
        countryImg: ImageThree,
        tax1: '25%',
        tax2: '36%',
        tax3: '22%',
        tax4: '42%',
        color: 'black',
        fontW: '0'
    },
    {
        countryImg: ImageFour,
        tax1: '34%',
        tax2: '28%',
        tax3: '17%',
        tax4: '65%',
        color: 'black',
        fontW: '0'
    },
    {
        countryImg: ImageFive,
        tax1: '25%',
        tax2: '13%',
        tax3: '13%',
        tax4: '84%',
        color: 'black',
        fontW: '0'
    }
];
