import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Img from '../images/Wpp/whatsapp.png'


const Button = styled(Link)`

    position:fixed;
    width:60px;
    height:60px;
    line-height: 63px;
    bottom:25px;
    left:25px;
    background:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:35px;
    box-shadow: 0px 1px 10px rgba(0,0,0,0.3);
    z-index:100;
    transition: all 300ms ease;
    display:flex;
    align-items: center;
    justify-content: center;
    animation: bounce 1s infinite alternate;
    -webkit-animation: bounce 1s infinite alternate;


    &:hover
    {
        background: #20ba5a;
        animation: none;
    }

    @keyframes bounce 
    {
        from 
        {
            transform: translateY(0px);
        }
        to 
        {
            transform: translateY(-15px);
        }
    }

@media only screen and (min-width:320px) and (max-width:768px)
{
    width:63px;
    height:63px;
    line-height: 66px;
}
`
const ImgBtn = styled.img`
    width:40px;
    height:40px;

`

const Whatsapp = () => {
    return (
        <Button target="_blank" to='//api.whatsapp.com/send?phone=+595981361122'>
            <ImgBtn src={Img}/>
        </Button>
    )
}

export default Whatsapp