import React, { useEffect } from 'react';
import styled from 'styled-components'
import TextEffect from '../images/TitleEffect.png';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer'
import NoFoundImg from '../images/404/noresult.png'


const Section = styled.div`
    display:flex;
    flex-direction: column;
    padding: 12rem 6rem;
    padding-top: 0;
    @media (max-width: 1230px) 
    {
        padding: 7rem 4rem;
        padding-top: 0;
    }
    @media (max-width: 720px) 
    {
        padding: 3rem;
        padding-top: 0;
    }
    @media (max-width: 384px) 
    {
        padding: 2rem;
        padding-top: 0;
    }
`
const Title = styled.div`
    padding-bottom: 2rem;
    h1
    {
        padding-bottom: 2rem;
        font-size: clamp(1rem,8vw,2rem);
        font-weight: 400;
    }
    h4
    {
        font-weight: 600;
        font-style: italic;
    }

    span
    {
        color:#ffb400;
        font-weight: 600;
        font-style: italic;
	  
        background: url(${TextEffect}) repeat-y;
	    -webkit-background-clip: text;
	    background-clip: text;


	    -webkit-text-fill-color: transparent;
	    -webkit-animation: aitf 37s linear infinite;

	    -webkit-transform: translate3d(0,0,0);
	    -webkit-backface-visibility: hidden;
    }

    @keyframes aitf 
    {
        0% { background-position: 0% 50%; }
        100% { background-position: 100% 50%; }
    }
`

const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: center;

    @media (max-width: 1144px)
    {
       grid-template-columns: repeat(2, 1fr);
       row-gap: 2rem;
    }
    @media (max-width: 550px)
    {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 2rem;   
    }

`

const Card = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    height: 300px;
    background: #ffb400;
    border-radius: 15px;
    padding: 1rem;
    text-align: center;

    @media (max-width: 1390px) 
    {
        width: 240px;
        height: 260px;
    }
    @media (max-width: 650px)
    {
        width: 190px;
        height: 210px;
        font-size: 12px;
    }

`
const Img = styled.img`

    width: 150px;
    height: 150px;

    @media (max-width: 1390px) 
    {
        width: 100px;
        height: 100px;
    }
`

const CardDesc = styled.div`
    display:flex;
    text-align: center;
    padding-top: 10px;
    color: white;
    height: 50%;
`


const Other = ({whyData}) =>
{
    const { ref, inView } = useInView({
        triggerOnce: true,
    });
    const animation1 = useAnimation();

    useEffect(() => {
        if (inView) {
            animation1.start(index =>({
                x: 0,
                opacity: 1,
                transition:{
                type: 'spring',
                duration: 2,
                bounce: 0.3,
                opacity: 0,
                delay: index * 0.3
                }
            }))
        }
        if (!inView) {
            animation1.start({ x: '-50vh', opacity: 0, transition: { duration: 0 }})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView])

    return (
        <Section>
        <Title style={{ textAlign: 'center'}}><h1>OTROS <span>BENEFICIOS</span></h1></Title>
        <CardsContainer ref={ref}>
                {whyData.map((item, index) => (
                <motion.div key={index}
                custom={index}
                animate={animation1}
                style={{ display:"flex", alignItems:"center", justifyContent:"center"}}
                >
                    <Card>
                        <Img src={item.image}
                                alt={NoFoundImg}
                        />
                        <CardDesc>{item.description}</CardDesc>
                    </Card>
                </motion.div>
            ))}
        </CardsContainer>
        </Section>
    ) 
}
export default Other;