
import React from "react";
import styled from 'styled-components';
import TextEffect from '../images/TitleEffect.png'
import TrashImg from '../images/trash.png'
import { Button } from "./Button";
import { deleteTerreno } from '../data/api';

const Container = styled.div`
    position:fixed;
    width: 100%;
    height: 70%;
    z-index: 1000;
    display:flex;
    align-items: center;
    justify-content: center;
    @media (max-height: 400px)
    {
        height: 50%;
    }
    
`
const Background = styled.div`
    width:850px;
    height:305px;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: url(${TextEffect}) repeat-y;
	-webkit-animation: aitf 10s linear infinite;
	-webkit-transform: translate3d(0,0,0);
    @media (max-width: 925px)
    {
        width:100%;
        margin: 0 3rem;
    }
    @media (max-width: 535px)
    {
       font-size: 10px;

    }
    @media (max-height: 400px)
    {
        height:220px;
    }
    
    @keyframes aitf 
    {
        0% { background-position: 0% 50%; }
        100% { background-position: 100% 50%; }
    }
    img
    {
        @media (max-height: 400px)
        {
            display: none;
        }
    }

`
const Contains = styled.div`
    width:850px;
    height:280px;
    background: black;
    color: white;
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    @media (max-width: 925px)
    {
        width:100%;
        height:280px;
    }
    @media (max-height: 400px)
    {
        height:200px;
    }
    h2
    {
        padding-bottom: 1rem;
    }
    img
    {
        padding: 1rem 0px;
        width: 64px;
    }
`

const Buttons = styled.div`
    display:grid;
    padding-top: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 1rem;
`

function PopUp(props) {

    const DeleteFile = async (element) => {

        await deleteTerreno(element);
        props.setTrigger(false);
        props.getMultiple();
    }

    return(props.trigger) ? (
        <Container>
            <Background>
                <Contains>
                <h2>¿Realmente desea Eliminar</h2>
                <h4>"{props.element.pais},{props.element.dpto},{props.element.loc}"?</h4>
                <img src={TrashImg} alt="a"></img>
                <Buttons>
                    <Button to='' type="button" onClick={() => DeleteFile(props.element)}>Eliminar</Button>
                    <Button to='' type="button" onClick={() => props.setTrigger(false)}>Cancelar</Button>
                </Buttons>
                </Contains>
            </Background>
        </Container>
    ) : "";
}
export default PopUp;