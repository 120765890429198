import React, { useContext } from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components'
import AuthContext from '../context/AuthContext';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import LogOutBtn from './LogOutBtn';

const DropdownContainer = styled.div`
    position: fixed;
    width: 100%;
    z-index: 10000;
    height: 100%;
    background: #ffb400;
    display:grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s; ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '1' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`
const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`
const CloseIcon = styled(FaTimes)`
    color: white;
`
const DropdownWrapper = styled.div`
    
`
const DropdownMenu = styled.div`
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 80px);
    text-align: center;
    margin-bottom: 4rem;

    @media (max-width 480px)
    {
        grid-template-rows: repeat(4, 60px);
    }
`
const DropdownLink = styled(Link)`
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    color: #fff;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover
    {
        color: #000d1a;
    }
`
const BtnWrap = styled.div`
    display: flex;
    justify-content: center;

`

const DropDown = ({isOpen, toggle}) => {

    const { loggedIn } = useContext(AuthContext);

    return (
    <DropdownContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>   
        <DropdownWrapper>
            <DropdownMenu>
                <DropdownLink to='/'>
                    Inicio
                </DropdownLink>
                <DropdownLink to='/terrains'>
                    Terrenos
                </DropdownLink>
                <DropdownLink to='/aboutUs'>
                    Sobre Nosotros
                </DropdownLink>
                    <BtnWrap style={{ marginTop: '20px' }}>
                    {
                        loggedIn === true && (
                            <LogOutBtn />
                        )
                    }
                    </BtnWrap>
                <BtnWrap style={{marginTop: '20px'}}>
                    <Button to='/contact' style={{background: 'white' , color: '#ffb400', fontSize:'24px'}}>
                        Contactanos
                    </Button>
                </BtnWrap>
            </DropdownMenu>
        </DropdownWrapper> 
    </DropdownContainer>
  ) 
};

export default DropDown;
