import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getById } from '../data/api'
import styled from "styled-components";
import TextEffect from '../images/TitleEffect.png';
import './Terrain.css';

import BeatLoader from 'react-spinners/BeatLoader'
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

import imgPrec from '../images/Terrain/prec.png'
import imgHa from '../images/Terrain/ha.png'
import imgVis from '../images/Terrain/vis.png'

const NavSpace = styled.div`
    background: black;
    height: 60px;
`
const Section = styled.section`
    width: 100%;
    height: 100%;
    overflow: hidden;
`

const Title = styled.div`
    padding: 3rem 4rem;
    padding-bottom: 0;
    @media (max-width: 450px) 
    {
        padding: 3rem 2rem;
    }

    h1
    {
        font-size: clamp(1rem,8vw,2rem);
        font-weight: 400;
    }
    h4
    {
        color: #ffb400;
        font-weight: 600;
        font-style: italic;
    }

    span
    {
        color:#ffb400;
        font-weight: 600;
        font-style: italic;

        background: url(${TextEffect}) repeat-y;
	    -webkit-background-clip: text;
	    background-clip: text;


	    -webkit-text-fill-color: transparent;
	    -webkit-animation: aitf 37s linear infinite;

	    -webkit-transform: translate3d(0,0,0);
	    -webkit-backface-visibility: hidden;
    }

    @keyframes aitf 
    {
        0% { background-position: 0% 50%; }
        100% { background-position: 100% 50%; }
    }
`

const Background = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    background: white;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    display: flex;
`
const TerrainContainer = styled.div`
    display:flex;
    width: 100%;
    height: 100%;
    padding: 2rem;
    @media (max-width: 869px) 
    {
        flex-direction: column;
    }
    @media (max-width: 450px) 
    {
        padding:0;
    }
`
const InfoTerrain = styled.div`
    height: 100%;
    width: 50%;
    padding: 0 4rem;
    padding-top: 0;

    @media (max-width: 869px) 
    {
        padding: 0 2rem;
    }
    p
    {
        white-space: pre-wrap;
        width: 100%;
        height: 500px;
        overflow-x: hidden;
        overflow-y: auto;
        ::-webkit-scrollbar 
        {
            width: 5px;
        }
            
        ::-webkit-scrollbar-track 
        {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: black;
        }
            
        ::-webkit-scrollbar-thumb 
        {
            background-color: #ffb400;
        }

        box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
        border-radius: 15px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 1rem;
    }
`
const ImagesTerrain = styled.div`
    width: 50vw;
    margin-bottom: 10rem;
    @media (max-width: 869px) 
    {
        width: 100%;
        padding: 0 2rem;
        padding-top: 0rem;
    }
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1em;
    text-align:center;
    justify-items:center;
    align-items:center;
`
const Text = styled.div`
    font-weight: 400;
    font-size: 30px;
    @media (max-width: 410px) 
    {
        font-size: 18px;
    }
    span
    {
        color:#ffb400;
    }
`
const Img = styled.img`
    width: 145px;
    @media (max-width: 410px) 
    {
        width: 100px;
    }
`
const Description = styled.div`
    margin: 4rem;
    margin-top: 0;
    @media (max-width: 450px) 
    {
        margin: 2rem;
    }
    h1
    {
        font-size: clamp(1rem,8vw,2rem);
        font-weight: 400;
        padding: 2rem;
    }
    span
    {
        color:#ffb400;
        font-weight: 600;
        font-style: italic;

        background: url(${TextEffect}) repeat-y;
	    -webkit-background-clip: text;
	    background-clip: text;


	    -webkit-text-fill-color: transparent;
	    -webkit-animation: aitf 37s linear infinite;

	    -webkit-transform: translate3d(0,0,0);
	    -webkit-backface-visibility: hidden;
    }
    p
    {
        padding:2rem;
        white-space: pre-wrap;
        width: 100%;
        height: 500px;
        overflow-x: hidden;
        overflow-y: auto;
        box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
        border-radius: 15px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        ::-webkit-scrollbar 
        {
        width: 5px;
        }
                
        ::-webkit-scrollbar-track 
        {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: black;
        }
                
        ::-webkit-scrollbar-thumb 
        {
            background-color: #ffb400;
        }        
        
        @media (max-width: 400px) 
        {
            font-size: 12px;
        }
    }
`

function Terrain() {
    const [loading, setLoading] = useState(false);
    const [terrain, setTerrain] = useState([]);

    let { id } = useParams();

    function pointsPrice(price) {
        return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
    const getSingleFile = async () => {
        try {
            const file = await getById(id);
            setTerrain(file)
            setLoading(true);

        }
        catch (error) {
            console.log(error);
        }
    }
   
    useEffect(() => {
        getSingleFile();
        // eslint-disable-next-line
    }, []);
    return (
        <>
            {loading ?
                <Section>
                    <NavSpace />
                    <Title style={{ textTransform: 'uppercase' }}>
                        <h1>{terrain[0].loc}, {terrain[0].dpto}, <span>{terrain[0].pais}</span></h1>
                    </Title>
                    <TerrainContainer>
                        <ImagesTerrain>
                            <ImageGallery items={terrain[0].files.map(item => ({ original: item.filePath, thumbnail: item.filePath }))} />
                        </ImagesTerrain>
                        <InfoTerrain>
                            <Grid>
                                <Img src={imgPrec}></Img>
                                <Text><span>US$ </span>{pointsPrice(terrain[0].prec)} {terrain[0].porh ? 'Por Hectárea.' : ''}</Text>
                                <Img src={imgHa}></Img>
                                <Text>{pointsPrice(terrain[0].ha)} Hectáreas.</Text>
                                <Img src={imgVis}></Img>
                                <Text>{terrain[0].vis} Visitas.</Text>
                            </Grid>
                        </InfoTerrain>
                    </TerrainContainer>
                    <Description>
                        <h1><span>DESCRIPCIÓN</span></h1>
                        <p>{terrain[0].des}</p>
                    </Description>
                </Section>
                :
                <Background>
                    <BeatLoader
                        color={'#ffb400'}
                        size={50}
                    />
                </Background>
            }
        </>
    );
}
export default Terrain;