import axios from "axios";
import React, {useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AuthContext from "../context/AuthContext";
import TextEffect from '../images/TitleEffect.png';
import ImgSignIn from '../images/signin.svg';

const Section = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    min-height: 100vh;
`
const NavSpace = styled.div`
    background: black;
    height: 60px;
`
const Container = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    padding-top: 4rem;
    @media (max-width: 1124px) 
    {
        flex-direction: column-reverse;
        padding-top: 4rem;
    }
    
`

const Form = styled.form`
    display:flex;
    flex-direction: column;
`

const Title = styled.div`
    padding-bottom: 2rem;
    h1
    {
        font-size: clamp(1rem,8vw,2rem);
        font-weight: 400;
    }
    h4
    {
        font-weight: 600;
        font-style: italic;
    }

    span
    {
        color:#ffb400;
        font-weight: 600;
        font-style: italic;
	  
        background: url(${TextEffect}) repeat-y;
	    -webkit-background-clip: text;
	    background-clip: text;


	    -webkit-text-fill-color: transparent;
	    -webkit-animation: aitf 37s linear infinite;

	    -webkit-transform: translate3d(0,0,0);
	    -webkit-backface-visibility: hidden;
    }

    @keyframes aitf 
    {
        0% { background-position: 0% 50%; }
        100% { background-position: 100% 50%; }
    }
`
const Left = styled.div`
    width:50%;
    display: flex;
    justify-content: center;
`

const Right = styled.div`
    width:50%;
    @media (max-width: 1124px) 
    {
        padding-bottom: 2rem;
    }
    @media (max-width: 570px) 
    {
        width:100%;
        padding: 2rem 4rem;
        padding-top: 0;
    }
    @media (max-width: 380px) 
    {
        padding: 2rem 2rem;
        padding-top: 0;
    }
`

const Button = styled.button`
    background: #ffb400;
    white-space: nowrap;
    width: 200px;
    height: auto;
    outline: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    color: #fff;
    font-size: 2.5vh;
    
    &:hover
    {
        transform: translateY(-7px);
    }
    @media (max-width: 380px) 
    {
        width: 70%;
    }
`
const Img = styled.img`
    height: 400px;
    @media (max-width: 570px) 
    {
        height: 200px;

    }
    @media (max-width: 335px) 
    {
        height: 150px;

    }
`
const Input = styled.input`
    width: 50%;
    border: none;
    font-size: 1.5rem;
    border-bottom: 1px solid black;
    margin-bottom: 2rem;
    @media (max-width: 1124px) 
    {
        width: 100%;
    }
`

function Login()
{
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const {getLoggedIn} = useContext(AuthContext)

    async function login(e)
    {
        e.preventDefault();

        try
        {
            const loginData =   
            {
                user,
                password
            }
            //await axios.post('http://localhost:8000/api/login', loginData, { withCredentials: true});
            await axios.post('https://cpi-sudamerica-app.onrender.com/api/login', loginData, { withCredentials: true });
            await getLoggedIn();
            navigate('/admin')

        }
        catch(error)
        {
            console.error(error)
        }
    }
    return (
        <Section>
            <NavSpace/>
            <Container>
                <Left>
                    <Img src={ImgSignIn}></Img>
                </Left>
                <Right>
                    <Title><h1>Hola Administrador de CPI-Sudamerica!</h1></Title>
                    <Form onSubmit={login}>
                        <Input type="user" value={user} onChange={(e) => setUser(e.target.value)} placeholder="Correo..." ></Input>
                        <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Contraseña..." ></Input>
                        <Button type="submit">Acceso</Button>
                    </Form>
                </Right>
            </Container>
        </Section>
    )

}
export default Login;