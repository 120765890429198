import React, { useState, useRef, useEffect} from 'react';
import styled, { css } from 'styled-components/macro';
import { Button } from './Button';
import { IoMdArrowRoundForward } from 'react-icons/io';
import { IoArrowForward, IoArrowBack} from 'react-icons/io5';

import NoFoundImg from '../images/404/noresult.png'

const SliderButtons = styled.div`
    position: absolute;
    bottom: 50px;
    right: 50px;
    display: flex;
    z-index: 10;
`

const HeroSection = styled.section`
    height: 100vh;
    max-height: 1100px;
    position: relative;
    overflow: hidden;
`;

const HeroWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
`

const HeroSlide = styled.div`
    z-index: 1;
    width: 100%;
    height: 100%;
`;

const HeroSlider = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before
    {
        content: '';
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100vh;
        bottom: 0vh;
        left: 0;
        overflow: hidden;
        opacity: 0.4;
        background: linear-gradient(
        0deg, 
        rgba(0,0,0,0.2) 0%,
        rgba(0,0,0,0.2) 50%, 
        rgba(0,0,0,0.6) 100%);
    }
`;
const HeroImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    animation: fadein 1s;

    @keyframes fadein 
    {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
`;
const HeroContent = styled.div`
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    max-width: 1600px;
    width: calc(100% - 100px);
    color: #fff;
    animation: fadeIn 0.5s ease-in both;
    h1 
    {
        font-size: clamp(1rem, 8vw, 2rem);
        font-weight: 400;
        text-trasnform: uppercase;
        text-shadow: 0px 0px 20px rgba(0,0,0,0.4);
        text-align: left;
        margin-bottom: 0.8rem;
    }

    p
    {
        margin-bottom: 1.2rem;
        text-shadow: 0px 0px 20px rgba(0,0,0,0.4);
    }

    @keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
`;


const Arrow = styled(IoMdArrowRoundForward)`
    margin-left: 0.5rem;

`;

const arrowButtons = css`
    width: 50px;
    height: 50px;
    color: #fff;
    cursor: pointer;
    background: #000d1a;
    border-radius: 50px;
    padding: 10px;
    margin-right: 1rem;
    user-select: none;
    transition: 0.2s;

    &:hover
    {
        background: #ffb400;
        transform: scale(1.1);
        transition: 0s;
    }
`
const PrevArrow = styled(IoArrowBack)`
    ${arrowButtons}
`

const NextArrow = styled(IoArrowForward)`
    ${arrowButtons}
`
const TextContent = styled.div`
    display: flex;
`


const Hero = ({ data }) => 
{
    const [current, setCurrent] = useState(0);
    const leng = data.length;
    const timeout = useRef(null);

    function pointsPrice(price) {
        return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

    useEffect(() => {
        const nextSlide =() =>
        {
            setCurrent(current => (current === leng - 1 ? 0: current + 1))
        }

        timeout.current = setTimeout(nextSlide, 5000)

        return function ()
        {
            if(timeout.current)
            {
                clearTimeout(timeout.current)
            }
        }
    }, [current, leng])

    const nextSlide = () => 
    {
        if (timeout.current) {
            clearTimeout(timeout.current)
        }
        setCurrent(current === leng - 1 ? 0 : current + 1);
    }

    const prevSlide = () => 
    {
        if (timeout.current) {
            clearTimeout(timeout.current)
        }
        setCurrent(current === 0 ? leng - 1 : current - 1 );
    }

    if (!Array.isArray(data) || data.length <= 0)
    {
        return null;
    }

    return (
        <HeroSection>
            <HeroWrapper>
                {data.map((item, index) => {
                    return (
                        <HeroSlide key={item._id}>
                            {index === current && (
                                <HeroSlider>
                                    <HeroImage src={item.files[0].filePath} 
                                        alt={NoFoundImg}/>
                                    <HeroContent>
                                        <h1>{item.loc}, {item.dpto}, {item.pais}</h1>
                                        <TextContent>
                                            <p>{pointsPrice(item.ha)}ha - {pointsPrice(item.prec)} U$S {item.porh ? 'p/h': null}</p>
                                        </TextContent>
                                        <Button to={`/terrain/${item._id}`} type="button">
                                            Ver Más
                                            <Arrow />
                                        </Button>
                                    </HeroContent>
                                </HeroSlider>
                            )}
                        </HeroSlide>
                    )
                })}
                <SliderButtons>
                    <PrevArrow onClick={prevSlide} />
                    <NextArrow onClick={nextSlide}/>
                </SliderButtons>
            </HeroWrapper>
        </HeroSection>
    )
}

export default Hero;