import styled from 'styled-components'
import React from 'react';
import AboutUs from '../components/AboutUs';

const NavSpace = styled.div`
    background: black;
    height: 60px;
    margin-bottom: 0.5rem;

`
const Container = styled.div`
    min-height: 100vh;
    padding-top: 3rem;
    display:flex;
    justify-content:center;
`
export default function Contact() {

    return (
        <>
            <NavSpace />
            <Container>
                <AboutUs />
            </Container>
        </>
    )
}