import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getById, updateTerreno, updateTerrenoSameImages } from '../data/api'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css';
import styled from "styled-components";
import TextEffect from '../images/TitleEffect.png';
import { Button } from "../components/Button";

import NoFoundImg from '../images/404/noresult.png'
import BeatLoader from 'react-spinners/BeatLoader'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const NavSpace = styled.div`
    background: black;
    height: 60px;
`
const Section = styled.div`
    width: 100%;
`
const TopSide = styled.div`
    padding: 2rem 0;
    display:flex;
    flex-direction: column;
    align-items: center;
`
const Title = styled.div`
    padding-bottom: 2rem;
    text-align: center;
    h1
    {
        font-size: 40px;
        font-weight: 400;
    }
    h4
    {
        font-weight: 600;
        font-style: italic;
    }

    span
    {
        color:#ffb400;
        font-weight: 600;
        font-style: italic;
	  
        background: url(${TextEffect}) repeat-y;
	    -webkit-background-clip: text;
	    background-clip: text;


	    -webkit-text-fill-color: transparent;
	    -webkit-animation: aitf 37s linear infinite;

	    -webkit-transform: translate3d(0,0,0);
	    -webkit-backface-visibility: hidden;
    }

    @keyframes aitf 
    {
        0% { background-position: 0% 50%; }
        100% { background-position: 100% 50%; }
    }
`

const Container = styled.div`
    display:flex;
    background: #fff1ca;
    margin: 20px 0px;
    height: 100%;
    padding: 1.5rem;
    @media (max-width: 715px) 
    {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        label
        {
            font-size: 12px;
        }
    }
    @media (max-width: 500px) 
    {
        grid-template-columns: repeat(1, 1fr);
    }
    label 
    {
        font-weight: 700;
    }
`
const Left = styled.div`
    display:flex;
    align-items: center; 
    flex-direction: column;
    width:40%;
    @media (max-width: 715px) 
    {
        width: 100%;
    }

    label
    {
        margin-bottom: 10px;
    }
`

const Right = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    @media (max-width: 715px) 
    {
        width: 100%;
    }

`
const Center = styled.div`
    width: 40%;
    display: flex;
    justify-content: center;
    @media (max-width: 715px) 
    {
        width: 100%;
    }
    @media (max-width: 500px) 
    {
        padding-top: 1rem;
    }
`
const InputContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    label
    {
        display:flex;
        justify-content: end;
        padding-right: 10px;
        align-items: center;
        text-align: end;
    }
    @media (max-width: 715px) 
    {
        grid-template-columns: repeat(1, 1fr);
        label
        {
            justify-content: start;
        }
    }
`
const Input = styled.input`
    width: 100%;
    height: 25px;
`
const Textarea = styled.textarea`
    width: 90%;
    height: 400px;
    resize: none;
    ::-webkit-scrollbar 
    {
        width: 5px;
    }
        
    ::-webkit-scrollbar-track 
    {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: black;
    }
        
    ::-webkit-scrollbar-thumb 
    {
        background-color: #ffb400;
    }
`
const ProgressContainer = styled.div`
    width: 25%;
    height: 170px;
    display:flex;
`
const Files = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    text-align: center;

    img
    {
        width: 80%;
        transition: max-height 0.15s ease-out;
    }
    @media (max-width: 1366px) 
    {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 814px) 
    {
        grid-template-columns: repeat(1, 1fr);
    }
    
`
const ImageWrap = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-between; 
`

const Background = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    background: white;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    display: flex;
`



function Edit() {

    const [editFile, SetEditFile] = useState([]);
    let { id } = useParams();

    const [des, setDes] = useState('')
    const [pais, setPais] = useState('');
    const [dpto, setDpto] = useState('');
    const [loc, setLoc] = useState('none');
    const [prec, setPrec] = useState(0);
    const [porh, setPorh] = useState(false);
    const [ha, setHa] = useState(0);
    const [vis, setVis] = useState(0);
    const [principal, setPrincipal] = useState(false);
    const [multipleFiles, setMultipleFiles] = useState('');
    const [multipleProgress, setMultipleProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    const getSingleFile = async () =>
    {
        try {
            const file = await getById(id);
            SetEditFile(file);
            setDes(file[0].des)
            setPais(file[0].pais)
            setDpto(file[0].dpto)
            setLoc(file[0].loc)
            setPrec(file[0].prec)
            setPorh(file[0].porh)
            setHa(file[0].ha)
            setVis(file[0].vis)
            setPrincipal(file[0].principal)
            setLoading(true)
        }
        catch (error) {
            console.log(error);
        }
    }
    const multipleFileOptions =
    {
        onUploadProgress: (ProgressEvent) => {
            const { loaded, total } = ProgressEvent;
            const percentage = Math.floor(((loaded / 1000) * 100) / (total / 1000));
            setMultipleProgress(percentage);
        }
    }


    const MultipleFileChange = (e) => {
        setMultipleFiles(e.target.files);
        setMultipleProgress(0);
    }

    const UpdateFile = async () => {
        setMultipleProgress(0);
        if (des === '')
        {
            return alert('Ingrese una Descripcion')
        }
        if (pais === '') {
            return alert('Ingrese un Pais');
        }
        if (dpto === '') {
            return alert('Ingrese un Departamento');
        }
        if (loc === '') {
            return alert('Ingrese una Localidad');
        }
        if (prec === 0) {
            return alert('Ingrese un Precio')
        }
        if (ha === 0) {
            return alert('Ingrese una cantidad de Hectareas');
        }
        if (multipleFiles.length === 0) {
            const formData = new FormData();
            formData.append('des', des)
            formData.append('pais', pais);
            formData.append('dpto', dpto);
            formData.append('loc', loc);
            formData.append('prec', prec);
            formData.append('porh', porh);
            formData.append('ha', ha);
            formData.append('vis', vis);
            formData.append('principal', principal);
            formData.append('files', editFile[0].files)
            await updateTerrenoSameImages(formData, id, multipleFileOptions)
            getSingleFile();
        }
        else
        {
            const formData = new FormData();
            formData.append('des', des)
            formData.append('pais', pais);
            formData.append('dpto', dpto);
            formData.append('loc', loc);
            formData.append('prec', prec);
            formData.append('porh', porh);
            formData.append('ha', ha);
            formData.append('vis', vis);
            formData.append('principal', principal);

            for (let i = 0; i < multipleFiles.length; i++) {
                if (multipleFiles[i].type === 'image/png' || multipleFiles[i].type === 'image/jpg'
                    || multipleFiles[i].type === 'image/jpeg') {
                    formData.append('files', multipleFiles[i])
                }
                else {
                    return alert('Solo imagenes: .png .jpg .jpeg');
                }
            }
            await updateTerreno(formData, id, multipleFileOptions);
            getSingleFile();
        }
    }
    
    useEffect(() => {
        getSingleFile();
        // eslint-disable-next-line
    }, []);

    function toggle(value) {
        return !value;
    }

    return (
        <>
            { loading ? 
                <Section>
                    <NavSpace />
                    <TopSide>
                        <Title><h1>EDITAR UN <span>TERRENO</span></h1></Title>
                        <Button to='/admin' type="button" >← Volver al Administrador</Button>
                    </TopSide>
                    <Container>
                        <Left>
                            <label>Descripcion:</label>
                            <Textarea value={des} onChange={(e) => setDes(e.target.value)} ></Textarea>
                        </Left>
                        <Center>
                            <InputContainer>
                                <label>Pais:</label>
                                <Input type="text" value={pais} onChange={(e) => setPais(e.target.value)}></Input>
                                <label>Departamento:</label>
                                <Input type="text" value={dpto} onChange={(e) => setDpto(e.target.value)} ></Input>
                                <label>Localidad:</label>
                                <Input type="text" value={loc} onChange={(e) => setLoc(e.target.value)} ></Input>
                                <label>Precio:</label>
                                <Input type="number" value={prec} onChange={(e) => setPrec(e.target.value)} ></Input>
                                <label>¿Precio Por hectarea?:</label>
                                <Input type="checkbox" checked={porh} onChange={() => setPorh(toggle)}></Input>
                                <label>Hectareas:</label>
                                <Input type="number" value={ha}  onChange={(e) => setHa(e.target.value)} ></Input>
                                <label>Visitas:</label>
                                <Input type="number" value={vis}  onChange={(e) => setVis(e.target.value)} ></Input>
                                <label>¿Aparecera en el inicio?:</label>
                                <Input type="checkbox" checked={principal} onChange={() => setPrincipal(toggle)}></Input>
                                <label>Imagenes:</label>
                                <Input type="file" onChange={(e) => MultipleFileChange(e)} multiple></Input>
                            </InputContainer>
                        </Center>
                        <Right>
                            <Button to='' type="button" onClick={() => UpdateFile()}>Cargar</Button>
                            <ProgressContainer>
                                <CircularProgressbar
                                    value={multipleProgress}
                                    text={`${multipleProgress}%`}
                                    styles={buildStyles({
                                        rotation: 0.25,
                                        strokeLinecap: 'butt',
                                        textSize: '16px',
                                        pathTransitionDuration: 0.5,
                                        pathColor: `rgba(255, 136, 136, ${multipleProgress / 100})`,
                                        textColor: '#f88',
                                        trailColor: '#d6d6d6',
                                        backgroundcolor: '#3e98c7',
                                    })}
                                />
                            </ProgressContainer>
                        </Right>
                    </Container>
                    <Title><h1><span>IMAGENES</span></h1></Title>
                    {editFile.map((element) =>
                        <Files key={element._id}>
                            {element.files.map((file, index) =>
                                <ImageWrap key={index}>
                                    <LazyLoadImage
                                        key={file.filePath}
                                        effect="blur"
                                        src={file.filePath}
                                        alt={NoFoundImg}
                                        height="400px"
                                        width="100%"
                                        style={{ padding: "2rem" }}
                                    />
                                </ImageWrap>
                            )}
                        </Files>
                    )}      
                </Section>
            :
            <Background>
                <BeatLoader
                    color={'#ffb400'}
                    size={50}
                />
            </Background>
            }
        </>
    );
}
export default Edit;