import axios from 'axios';
var qs = require('qs');

const apiUrl = 'https://cpi-sudamerica-app.onrender.com/api/';

export const deleteTerreno = async (terreno) =>
{
    try
    {
        await axios.delete(apiUrl + 'deleteTerreno', {
            'params': { 'terreno': terreno},
            'paramsSerializer': function (params) {
                return qs.stringify(params)
            }})
    }
    catch(error)
    {
        throw error;
    }
}

export const multipleFilesUpload = async (data, options) =>
{
    try
    {
        await axios.post(apiUrl + 'multipleFiles', data, options); 
    }
    catch(error)
    {
        throw error;
    }
}

export const getMultipleFiles = async () =>
{
    try
    {
        const { data } = await axios.get(apiUrl + 'getMultipleFiles');
        return data;
    }
    catch(error)
    {
        throw error;
    }
}

export const getById = async (id) =>
{
    try 
    {
        const { data } = await axios.get(apiUrl + 'getById/'+ id);
        return data;
    }
    catch(error)
    {

    }
}
export const updateTerreno = async (data, id, options) =>
{
    try {
        await axios.put(apiUrl + 'updateTerreno/' + id, data, options);
    }
    catch (error) {
        throw error;
    }
}

export const updateTerrenoSameImages = async (data, id, options) => 
{
    try 
    {
        await axios.put(apiUrl + 'updateTerrenoSameImages/' + id, data, options);
    }
    catch (error) 
    {
        throw error;
    }
}