import ContactLayer from '../components/ContactLayer'
import Staff from '../components/Staff'
import styled from 'styled-components'
import React from 'react';

import { motion } from 'framer-motion';

const NavSpace = styled.div`
    background: black;
    height: 60px;
`
export default function Contact() {

    return (
        <>
            <NavSpace/>
            <motion.div 
                animate={{ x: ['-10vw', '0vw'], opacity: [0, 1,] }}
                transition={{ type: "spring", stiffness: 100 }}>
                <ContactLayer />
            </motion.div>
            <Staff/>
        </>
    )
}