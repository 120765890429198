import React from 'react';
import styled from 'styled-components'

import Hero from '../components/Hero'
import WhyChoose from '../components/WhyChoose'
import AboutUs from '../components/AboutUs'
import MorePopular from '../components/MorePopular'
import Comparison from '../components/Comparison'

import { useState, useEffect } from "react";
import { getMultipleFiles } from '../data/api';

import { GridData } from '../data/GridData';
import { TableData } from '../data/TableData';
import { WhyData } from '../data/WhyData'

import BeatLoader from 'react-spinners/BeatLoader'
import Other from '../components/Others';

const Background = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    background: white;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    display: flex;
`

function Home() {

    const [popularArray, setPopularArray] = useState([]);
    const [principalArray, setPrincipalArray] = useState([]);
    const [loading, setLoading] = useState(false);

    const getMultipleFilesList = async () => {
        try {
            const fileslist = await getMultipleFiles();
            const filteredArray = await fileslist.filter((res) => res.principal);
            setPrincipalArray(filteredArray)
            const sortByVis = await fileslist.sort((a, b) => (a.vis < b.vis) ? 1 : -1)
            setPopularArray(sortByVis);
            setLoading(true)

        }
        catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getMultipleFilesList();

    }, []);

    return (
        <>
            {loading ? 
            <div>
                <Hero data={principalArray} />
                <WhyChoose />
                <Comparison gridData={GridData} tableData={TableData} />
                <AboutUs />
                <Other whyData={WhyData}/>
                <MorePopular infoCards={popularArray} />
            </div> 
            :
            <Background>
                <BeatLoader 
                    color={'#ffb400'}  
                    size={50} 
                />
            </Background>
            }
        </>
    )
}

export default Home;