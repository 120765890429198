import axios from 'axios';
import React, { useContext } from 'react';
import styled from 'styled-components';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Btn = styled.button`
  background: white;
    white-space: nowrap;
    outline: none;
    border: none;
    min-width: 100px;
    max-width: 200px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    color: #ffb400;
    font-size:14px;
    
    &:hover{
        transform: translateY(-7px);
    }
`

function LogOutBtn() {
   
    const {getLoggedIn} = useContext(AuthContext);

    const navigate = useNavigate();

    async function logOut()
    {
       
        //await axios.get("http://localhost:8000/api/logout", { withCredentials: true });
        await axios.get("https://cpi-sudamerica-app.onrender.com/api/logout", { withCredentials: true });

        await getLoggedIn();
        navigate("/login");
    }

  return <Btn onClick={logOut}>
        Cerrar sesión
    </Btn>;
}

export default LogOutBtn;
