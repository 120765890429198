import { createGlobalStyle } from  'styled-components';

const GlobalStyle = createGlobalStyle`
    * 
    {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
    }
    html, body
    {
        overflow-x: hidden;
    }
    body::-webkit-scrollbar 
    {
        width: 5px;
    }
        
    body::-webkit-scrollbar-track 
    {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: black;
    }
        
    body::-webkit-scrollbar-thumb 
    {
        background-color: #ffb400;
    }
`;

export default GlobalStyle;
