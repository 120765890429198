import React from 'react';
import styled from 'styled-components/macro';
import { Button } from './Button';


const FooterContainer = styled.nav`
    height: 78px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 3.5rem;
    padding-left: 3rem;
    z-index: 100;
    position: absolute;
    width: 100%;
    background: black;
    font-style: italic;
    @media (max-width: 465px) 
    {
        padding: 1rem 2rem;
        padding-left: 2rem;
    }
`;

const Text = styled.div`
    color: white;
    display:flex;
    align-items:center;
    @media (max-width: 465px) 
    {
        font-size: 12px;
    }
`

const Footer = () => {

    return (
        <FooterContainer>
            <Text>© TODOS LOS DERECHOS RESERVADOS</Text>
            <Button to="/contact">Contactanos</Button>
        </FooterContainer>
    )
}

export default Footer
