import ImageOne from '../images/FlagsTable/img1.png';
import ImageTwo from '../images/FlagsTable/img2.png';
import ImageThree from '../images/FlagsTable/img3.png';
import ImageFour from '../images/FlagsTable/img4.png';
import ImageFive from '../images/FlagsTable/img5.png';

export const TableData = [
    {
        countryImg: ImageOne,
        rank: '1',
        color: '#ffb400',
        fontW: '700'
    },
    {
        countryImg: ImageTwo,
        rank: '2',
        color: 'black',
        fontW: '0'
    },
    {
        countryImg: ImageThree,
        rank: '3',
        color: 'black',
        fontW: '0'
    },
    {
        countryImg: ImageFour,
        rank: '4',
        color: 'black',
        fontW: '0'
    },
    {
        countryImg: ImageFive,
        rank: '5',
        color: 'black',
        fontW: '0'
    }
];