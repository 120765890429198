import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';

const AuthContext = createContext();

function AuthContextProvider(props) {
    
    const [loggedIn, setLoggedIn] = useState(undefined);

    async function getLoggedIn()
    {
        //const loggedInRes = await axios.get('http://localhost:8000/api/loggedIn', { withCredentials: true })
        const loggedInRes = await axios.get('https://cpi-sudamerica-app.onrender.com/api/loggedIn', { withCredentials: true })
        setLoggedIn(loggedInRes.data);
    }

    useEffect(() =>
    {
        getLoggedIn();
    }, [])
  
    return(
        <AuthContext.Provider value={{loggedIn, getLoggedIn}}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContext;
export {AuthContextProvider};
